<template>
    <div class="container pt-3">
        <div class="card card-default">
            <div class="card-header bg-black">
                <h3 class="card-title"><i class="fas fa-sms mr-2"></i>{{ $t('general.enviar') }} SMS</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="maximize">
                        <i class="fas fa-expand"></i>
                    </button>
                </div> 
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <select class="form-control frm" v-model="plantilla">
                                <option :value="{}" selected>Elige una plantilla</option>
                                <option v-for="plant in plantillas" :key="plant.id" :value="plant">{{ plant.titulo_texto }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-phone"></i></span>
                            </div>
                            <input readonly type="text" class="form-control" placeholder="Número Telf." :value="numero">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" v-model="contenido" placeholder="Texto SMS" rows="3"></textarea>
                    </div>
                    <div class="col-md-12">
                        <button type="button" @click="enviarSms()" class="btn btn-dark btn-block ">{{ $t('general.enviar') }}</button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default ({
    props: ['numero','asegurado','servicio','id'],
    setup() {

    },
    data() {
        return {
            plantillas: [],
            plantilla:{},
            contenido:'',
        }
    },
    mounted(){
        this.cargarPlantillas();
    },
    components: {
        
    },
    methods: {
        async cargarPlantillas(){
            const api = new PwgsApi();
            const aux = await api.get('/mensajes-predefinidos?filters={"tipo_mensaje":{"value":"sms","matchMode":"equals"}');
            this.plantillas = aux.datos;
        },
       async enviarSms() {
            //PUT index.php/servicios/:id/enviar-sms
            const api = new PwgsApi();
            let subidadatos = { mensaje: this.contenido, numero: this.numero }
            try {
                var resp = await api.put('servicios/' + this.$props.id + '/enviar-sms', subidadatos);
                console.log('rrr', resp);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

       },
    },
    watch:{
        plantilla() {
            if(this.plantilla=={}){
                this.contenido='';
            }else{
                this.contenido = this.plantilla.texto;

            }
        }
    }
})


</script>