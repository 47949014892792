<template>
    <div class="row">
        <div class="col-lg-6" style="max-width:300px">
            <servicio-datos-visitas-visita-operario :servicio="servicio" :id="id"  :visita="visita"/>
            <servicio-datos-visitas-visita-cliente :servicio="servicio" :id="id"  :visita="visita" />
        </div>
        <div class="col-lg-6">
            <div class="d-flex flex-wrap">
                <div class="btn-group">
                    <button v-if="!finalizada" @click="eliminarvisita()" title="Eliminar visita" class="btn btn-default ">
                        <i class="fas fa-times" style="color:red;"></i>
                    </button>
                    <button type="button" v-if="confirmar"  @click="mostrarConfirmar()"  title="Confirmar Visita" class="btn btn-default ">
                        <i class="fas fa-align-justify"></i>
                    </button>
                    <button type="button" v-if="mostrarboton==true"  @click="mostrarTrabajos()" :style="'background:'+colorboton" title="Mostrar Trabajos" class="btn btn-default ">
                        <i class="far fa-plus-square"></i>
                    </button>
                    <button v-if="mostrarPresupuesto" type="button" @click="mostrarpresupuestos" class="btn btn-default " :style="'background:' + colorbotonpresup"  title="Presupuesto">
                        <i class="far fa-folder-open"></i>
                    </button>
                    <button type="button" v-if="!finalizada || modificarcitasfinalizadas"  :style="'background:' + colorbotondos" @click="mostrarEditar()" class="btn btn-default " title="Modificar visita">
                        <i class="far fa-edit"></i>
                    </button>
                    <button type="button" v-if="mostrarbotontres == true" title="Mostrar Chat" :style="'background:' + colorbotontres" @click="mostrarChat()" class="btn btn-default " >
                            <i class="far fa-comment-dots"></i>
                    </button>
                    <button v-if="!finalizada" type="button" class="btn btn-default " v-on:click="mostrarFinalizarBtn()" :style="'background:' + colorbotoncuatro" title="Finalizar Visita">
                        <i class="fas fa-check"></i>
                    </button>
                    <div class="btn-group open" style="width: 60px; margin: 0;">
                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu1">
                            <i class="fas fa-share-square"></i>
                        </button>
                        <ul id="dropdownMenu1" class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li class="dropdown-item"><a href="#" @click="parteoperarioemail()">{{ $t('general.enviarfichaemail') }}</a></li>
                            <li class="dropdown-divider"></li>
                            <li class="dropdown-item"><a href="#" @click="smsoperario()">{{ $t('general.smsaloperario') }}</a></li>
                            <li class="dropdown-item"><a href="#" @click="smsasegurado()">{{ $t('general.smsalasegurado') }}</a></li>
                            <li class="dropdown-item" v-if="ste"><a href="#" @click="steasegurado()">{{ $t('general.firmasteemail') }}</a></li>
                        </ul>
                    </div>
                    <div v-if="multiasistencia" class="btn-group open" style="width: 50px; margin: 0;">
                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu2">
                            <i class="far fa-file"></i>
                        </button>
                        <ul id="dropdownMenu2" class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li class="dropdown-item"><a href="#" @click="abrirventana()">Parte Genérico</a></li>
                            <li class="dropdown-item"><a href="#" @click="multi1()">{{ $t('multiasesistencia 1') }}</a></li>
                            <li class="dropdown-item"><a href="#" @click="multi2()">{{ $t('multiasesistencia 2') }}</a></li>
                            <li class="dropdown-item"><a href="#" @click="multi3()">{{ $t('multiasesistencia 3') }}</a></li>
                        </ul>
                    </div>
                    <div v-else-if="mapfre" class="btn-group open" style="width: 50px; margin: 0;">
                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu2">
                            <i class="far fa-file"></i>
                        </button>
                        <ul id="dropdownMenu2" class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li class="dropdown-item"><a href="#" @click="abrirventana()">Parte Genérico</a></li>
                            <li class="dropdown-item"><a href="#" @click="mapf()">Mapfre</a></li>
                        </ul>
                    </div>
                    <button v-else type="button" class="btn btn-default " v-on:click='abrirventana' title="Parte Genérico">
                        <i class="far fa-file"></i>
                    </button>
                </div>
            </div>            
            <servicio-datos-visitas-visita-archivos :servicio="servicio" :id="id" :visita="visita"/>
            <Dialog :style="{ width: '50rem' }" header="Finalizar visita" v-model:visible="mostrarFinalizar" @update:visible="mostrarFinalizar = false">                    
                <div style="display: flex; flex-direction: column; align-items: center; ">
                    <h5>Trabajos realizados</h5>
                    <textarea v-model="trabajosRealizados" class="form-control" id="realizados" rows="4" style="width: 70%;"></textarea>
                    <h5 style="margin-top: 10px;">Trabajos pendientes</h5>
                    <textarea v-model="trabajosPendientes" class="form-control" id="pendientes" rows="4" style="width: 70%;"></textarea>
                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;margin-top: 20px" @click="finalizarvisita()">Finalizar</button>
                </div>                    
            </Dialog>
       </div> 
       <div class="col-lg-12">
        <servicio-datos-visitas-visita-gestor :servicio="servicio" :id="id" :visita="visita"/>
       </div>
    </div>
    <visita-editar 
        :idservicio="id"
        :idvisita="visita.visita.idvisitador"
        :visita="visita"
        :servicio="servicio"
    />
    <visita-confirmar 
        :idservicio="id"
        :idvisita="visita.visita.idvisitador"
        :visita="visita"
        :finalizada="finalizada"
        :citasfinalizadas="modificarcitasfinalizadas"
    />
</template>
<script>
import Dialog from 'primevue/dialog';
import serviciosdatosvisitasvisitaoperario from './visita_operario.vue'
import serviciosdatosvisitasvisitacliente from './visita_cliente.vue'
import serviciosdatosvisitasvisitaarchivos from './visita_archivos.vue'
import serviciosdatosvisitasvisitagestor from './visita_gestor.vue'
import visitaeditar from './visita_editar.vue'
import visitaconfirmar from './visita_confirmar.vue'
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','visita','planning','colorboton','colorbotondos','colorbotontres','colorbotoncuatro'],
    setup() {
        return;
    },
    components: {
        'visita-confirmar': visitaconfirmar,
        'visita-editar': visitaeditar,
        'servicio-datos-visitas-visita-gestor': serviciosdatosvisitasvisitagestor,
        'servicio-datos-visitas-visita-operario': serviciosdatosvisitasvisitaoperario,
        'servicio-datos-visitas-visita-cliente': serviciosdatosvisitasvisitacliente,
        'servicio-datos-visitas-visita-archivos': serviciosdatosvisitasvisitaarchivos,
        Dialog,
    },
    data() {
        return {
            confirmar:false,
            mostrarPresupuesto: false,
            url: "",
            mostrarboton: false,
            mostrarbotondos: false,
            mostrarbotontres: false,
            colorbotonpresup:'',
            multiasistencia: false,
            ms:[],
            mapfre: false,
            mapfreUrl:'',
            finalizada: false,
            visible:false,
            visibleConfirmar: false,
            modificarcitasfinalizadas: false,
            ste:false,
            visitaAMostrar:this.$props.visita,
            trabajosRealizados:'',
            trabajosPendientes:'',
            mostrarFinalizar:false,
        }
    },
    emits: ['cerrar'],
    methods: {
        mostrarFinalizarBtn(){            
            this.trabajosRealizados = '';
            this.trabajosPendientes = '';
            this.mostrarFinalizar = true;
        },
        mapf(){
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + this.mapfreUrl, "Mapfre", "width=800, height=600");
        },
        multi1(){
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + this.ms[0], "Multiasistencia", "width=800, height=600");
        },
        multi2(){
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + this.ms[1], "Multiasistencia", "width=800, height=600");
        },
        multi3(){
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + this.ms[2], "Multiasistencia", "width=800, height=600");
        },
        eliminarvisita(){
            const api = new PwgsApi();
            const res = api.delete('planning-pwgs/'+this.$props.id+'/eliminar-visita/'+this.visita.visita.idvisitador);
            console.log('eliminado',res);
            this.$parent.obtenerVisitas(this.$props.id); 
        },
        async comprobarSe(){
            const api = new PwgsApi();            
            const se = await api.get('/servicios/'+this.id+'/comunicaciones-ste');
            if(se.comunicaciones==1){
                this.ste = true;
            }
        },
        cargardatos() {
            const api = new PwgsApi();     
            const activ = api.get('parametros-pwgs/modificar_citas_finalizadas');
            if(activ.modificar_citas_finalizadas==1){
                this.modificarcitasfinalizadas = true;
            }
            if(this.$props.visita.visita.confirmar==1){
                this.confirmar = true;
            }
            if (this.$props.planning > 0) {
                this.mostrarboton = true; 
                this.mostrarbotontres = true;
                this.mostrarPresupuesto = true;
            }
            if(this.$props.visita.visita.realizado_operario=='SI'){
                this.finalizada = true;
            }
            if(this.$props.visita.fichas_operario.MULTIASISTENCIA_WS){
                this.multiasistencia = true;
                this.ms=[this.$props.visita.fichas_operario.MULTIASISTENCIA_WS[0], this.$props.visita.fichas_operario.MULTIASISTENCIA_WS[1], this.$props.visita.fichas_operario.MULTIASISTENCIA_WS[2]];
            }
            if(this.$props.visita.fichas_operario.MAPFRE){
                this.mapfre = true;
                this.mapfreUrl=[this.$props.visita.fichas_operario.MAPFRE];
            }
            this.url = this.$props.visita.fichas_operario.generico.slice(5);
        },
        mostrarTrabajos() {
            this.$parent.$parent.$parent.toggleTrabajos();
        },
        mostrarEditar() {
            this.visible = true;
        },
        mostrarConfirmar(){
            this.visibleConfirmar = true;
        },
        mostrarChat() {
            this.$parent.$parent.$parent.toggleComunicaciones();
        },
        mostrarpresupuestos() {
            this.$parent.$parent.$parent.togglepresupuestos();
            if (this.$parent.$parent.$parent.presupuestos == false) { this.colorbotonpresup = ''; }
            if (this.$parent.$parent.$parent.presupuestos == true) { this.colorbotonpresup = 'green'; }
        },
        abrirventana() {
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + this.url, "Parte genérico", "width=800, height=600");
        },
        async finalizarvisita() {
            const visita = this.visitaAMostrar;
            const api = new PwgsApi();
            if(this.trabajosRealizados == ''){
                alert("Debe rellenar los trabajos realizados");
                return;
            }
            const body = {'trabajos_realizados_cita': this.trabajosRealizados, 'trabajos_pendientes_cita': this.trabajosPendientes};            
            try{
                const resp = await api.put('planning-pwgs/'+this.$props.id+'/finalizar-visita/'+visita.visita.idvisitador, body);
                console.log('finvisi', resp);
                this.mostrarFinalizar = false;
                this.$parent.obtenerVisitas(this.$props.id, this.visita.visita.idvisitador); 
            }catch(e){
                alert(e);
                console.error('error',e);
            }
        },
        async smsoperario() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-operario/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-operario/" + this.$props.visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al operario correctamente', life: 2000 });

            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async smsasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-asegurado/:id_visita
            const api = new PwgsApi();
            try {
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-asegurado/" + this.$props.visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al asegurado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async steasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ste-firma/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-ste-firma/" + this.$props.visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async parteoperarioemail() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ficha-operario/:id_visita
            const api = new PwgsApi();
            await api.put("planning-pwgs/" + this.$props.id + "/enviar-ficha-operario/" + this.$props.visita.visita.idvisitador);
        },
        iraservicio() {
            this.$router.push({ name: 'Servicio', params: { id: this.$props.visita.visita.servicios_idservicios } });
            this.$emit("cerrar");
            this.$parent.$parent.close();
        }
    },
    mounted() {
        this.comprobarSe();
        this.cargardatos();
    },
    watch: {
    }
})
</script>
<style>
.normal{
    background-color:green;
}
</style>
