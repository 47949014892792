<template>
    <div class="form-group">
        <div class="row">
            <div class="col-md-4">
                <button type="button" @click="nuevanota= !nuevanota" class="btn btn-default">{{ $t('general.nuevanota') }}</button>
            </div>
        </div>
        <br>
        <div class="card" v-if="nuevanota==true">
            <div class="card-header bg-gray">
                <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.nuevanota') }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <h5>{{ $t('general.nota') }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5>{{ $t('general.programaralarma') }}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <textarea v-model="textonota" rows="6" class="form-control"></textarea>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-6">
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{ $t('general.usuario') }}: &nbsp;</label>
                                <select class="custom-select form-control-sm select2" v-model="seleccionado">
                                    <option v-for="usuario in usuarios.datos" :value="usuario.idusuario" :key="usuario.idusuario"> {{ usuario.nombre }} </option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label >{{ $t('general.fecha') }}: &nbsp;</label>
                                <Calendar id="fnota" v-model="fechanota" :stepMinute="10" showTime hourFormat="24" dateFormat="dd/mm/yy" />
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row">
                            <div class="col-md-4">
                            </div>
                            <div class="col-md-4">
                                <Button severity="success" @click="sacarfecha();alarmascreadas.push( {hora:horanuevanota ,fecha:  fechanuevanota ,usuario: seleccionado })"> {{ $t('general.programaralarma') }}</Button>
                            </div>
                        </div>
                        <br>
                        <div v-if="alarmascreadas">
                            <div class="row" v-for="alarm in alarmascreadas" :key="alarm">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-10">
                                    <b> {{ $t('general.alarma') }} {{ $t('general.creada') }} </b> &nbsp;{{ $t('general.usuario') }}: {{alarm.usuario}} &nbsp; {{ $t('general.fecha') }}: {{alarm.fecha}}  {{alarm.hora}} &nbsp;&nbsp;<Button @click="alarmascreadas.splice(alarmascreadas.indexOf(alarm),1)" :style="{height:'1.5rem',width:'1.5rem',background:'red', border: '1px solid red' }" icon="pi pi-times" severity="danger"  rounded />
                                </div>
                                <div class="col-md-12">&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-5">
                    </div>
                    <div class="col-md-5">
                        <Button :style="{background:'rgb(34, 139, 57)',border:'1px solid #228b39'}" @click="crearnota(alarmascreadas)" severity="success">{{ $t('general.anadir') }}&nbsp;{{ $t('general.nota') }}</Button>
                    </div>
                </div>
            </div>
        </div>  
    </div>        
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.notas') }} {{ $t('general.tramitadores') }}</h3>
                </div>
                <div class="card-body">
                    <div class="direct-chat-messages" style="height: 550px;">
                        <div class="direct-chat-msg" v-for="nota in notas" :key="nota">
                            <div v-if="nota.tipo_usuario=='tramitador'">
                                <div class="direct-chat-infos clearfix">
                                    <span class="direct-chat-timestamp float-right">{{ nota.fecha }}</span>
                                </div>
                                <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg" alt="message user image">
                                <div class="direct-chat-text">
                                    <p @click="nota.anyadiralarma= !nota.anyadiralarma" v-html="nota.texto"></p>
                                    <div v-if="nota.anyadiralarma == true">
                                                    <p><b>{{ $t('general.usuario') }}:</b>&nbsp;
                                                    <select class="custom-select form-control-sm select2" v-model="userseleccionado">
                                                        <option v-for="usuario in usuarios.datos" :value="usuario.idusuario" :key="usuario.idusuario"> {{ usuario.nombre }} </option>
                                                    </select>
                                                    &nbsp;&nbsp;<br>
                                                    <b>{{ $t('general.fecha') }}:</b>&nbsp;<Calendar id="fnota" v-model="fechanota" :stepMinute="10" showTime hourFormat="24"  dateFormat="dd/mm/yy" /></p>
                                                    <button class="btn btn-default" @click="sacarfecha();crearalarm(nota.id_nota, fechanuevanota, horanuevanota, userseleccionado);nota.anyadiralarma = false">Nueva alarma</button>
                                                    <hr>
                                                </div>
                                    <div v-if="nota.alarmas">
                                        <h5>{{ $t('general.alarmas') }}</h5>
                                        <hr>
                                        <div v-for="alarma in nota.alarmas" :key="alarma">
                                            <div v-if="alarma.activa_alarma != 'NO'">
                                                <p><b>{{ $t('general.usuario') }}:</b>&nbsp;{{ alarma.nombre_usuario_alarma}}&nbsp;&nbsp;<br>
                                                <b>{{ $t('general.fecha') }}:</b>&nbsp;{{alarma.fecha_alarma }}&nbsp;&nbsp;{{alarma.hora_alarma }}</p>
                                                <button v-if="editaralarm != alarma.id_alarma" class="btn btn-default"  style="font-size: x-small;" @click="idseleccionada = alarma.usuario_alarma;editaralarm = alarma.id_alarma">Desactivar alarma</button>
                                                <hr v-if="editaralarm != alarma.id_alarma">
                                            </div>
                                            <div v-if="editaralarm == alarma.id_alarma">
                                                <!--<label >Motivo</label>-->
                                                <!--<textarea v-model="motivo" class="form-control"></textarea>-->
                                                <br>
                                                <button class="btn btn-default" @click="desactivaralarma(nota.id_nota,alarma.id_alarma);editaralarm='0'">Desactivar</button>&nbsp;<button class="btn btn-default" @click="editaralarm = '0'">Cancelar</button>
                                                <hr>
                                            </div>
                                            <div v-if="alarma.activa_alarma == 'NO'">
                                                <p><b>Alarma desactivada</b><br><b>{{ $t('general.usuario') }}:</b>&nbsp;{{ alarma.nombre_usuario_alarma }}&nbsp;&nbsp;<br>
                                                <b>{{ $t('general.fecha') }}:</b>&nbsp;{{ alarma.fecha_alarma }}&nbsp;&nbsp;{{ alarma.hora_alarma }}</p>
                                                <p v-html="alarma.nota_alarma"></p>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.notas') }} {{ $t('general.operarios') }}</h3>
                </div>
                <div class="card-body">
                    <div class="direct-chat-messages" style="height: 550px;">
                        <div class="direct-chat-msg" v-for="nota in notas" :key="nota">
                            <div v-if="nota.tipo_usuario == 'operario'">
                                <div class="direct-chat-infos clearfix">
                                    <!--<span class="direct-chat-name float-left">{{ mensaje.usuario_mensaje }}</span>-->
                                    <span class="direct-chat-timestamp float-right">{{ nota.fecha }}</span>
                                </div>
                                    <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg" alt="message user image">
                                <div class="direct-chat-text d-flex justify-content-between">
                                    <p v-html="nota.texto"></p>
                                    <div v-if="nota.alarmas">
                                        <div v-for="alarma in nota.alarmas" :key="alarma">
                                            <p>{{ $t('general.alarma') }}:</p>
                                            <p>{{ $t('general.usuario') }}:{{ nombre_usuario_alarma }} {{ $t('general.remitente2') }}:{{ nombre_remitente_alarma }}</p>
                                            <p>{{ alarma.fecha_alarma }}{{ hora_alarma }}</p>
                                        </div>
                                        <button  class="btn btn-default">{{$t('general.editaralarma')}}</button>
                                    </div>
                                    <input type="checkbox" class="checknotas" v-model="nota.revisado" @click="cambiarEstadoNota(nota.id_nota, nota.revisado)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            userseleccionado:'',
            notas: [],
            nuevanota: false,
            textonota: '',
            fechanota: new Date(),
            usuarios: '',
            editaralarm: false,
            fechaalarma:'',
            alarmascreadas: [],
            fechanuevanota: '',
            horanuevanota: '',
            idseleccionada: '',
            motivo:'',
        }
    },
    setup() {
        return;
    },
    components: {
    Calendar, Button,
},
    methods: {
        
        sacarfecha() { 
            this.fechanuevanota = new Date(this.fechanota).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(this.fechanota).toLocaleDateString("es-ES", { month: 'numeric' }) + "-" + new Date(this.fechanota).toLocaleDateString("es-ES", { year: 'numeric' });
            this.horanuevanota = new Date(this.fechanota).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' });
        },
        async cargarnotas() {
            
                const api = new PwgsApi();
                // Obtén las notas de la API
                this.notas = await api.get('servicios/' + this.$props.id + '/notas');
                
                // Si notas no es un array, asegúrate de que sea uno
                if (!Array.isArray(this.notas)) {
                    this.notas = [];
                }
                
                // Añadir el campo 'revisado' a cada nota
                this.notas = this.notas.map(nota => {
                    return this.comprobarRevisado(nota);
                });
        },
        comprobarRevisado(nota) {
            return {
                ...nota,
                revisado: nota.realizado_operario && nota.realizado_operario.toLowerCase() === "si"
            };
        },
        formatearfecha(fecha, hora) {
            this.fechaalarma = fecha + ' ' + hora;
        },
        async listaUsuarios() {
            /* get  modulos/pwgsapi/index.php/usuarios */
            const api = new PwgsApi;
            this.usuarios = await api.get('usuarios/operarios,tramitadores');
        },
        async crearnota() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/notas
            //PUT  modulos/pwgsapi/index.php/servicios/:id/notas/:id_nota
            const api = new PwgsApi;
            const subidadatos = { texto: this.textonota };
            await api.post('servicios/' + this.$props.id + '/notas', subidadatos);
            this.crearalarmas();
            this.cargarnotas();
        },
        async crearalarmas() {
            const api = new PwgsApi();
            let aux = await api.get('servicios/' + this.$props.id + '/notas');
            let idnota = aux[0].id_nota;
            if (this.alarmascreadas) {
                for (let i = 0; i < this.alarmascreadas.length; i++) {
                    let subidadatosdos = { hora: this.alarmascreadas[i].hora.slice(0, 2), minuto: this.alarmascreadas[i].hora.slice(Math.max(this.alarmascreadas[i].hora.length - 2, 0)), id_usuario: this.alarmascreadas[i].usuario, fecha: this.alarmascreadas[i].fecha.replace(/-/g, "/") }
                    await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatosdos);
                }
            }
        },
        async crearalarm(idnota,fecha,hora,idusuario) {
            const api = new PwgsApi();
            let subidadatosdos = { hora: hora.slice(0, 2), minuto: hora.slice(Math.max(hora.length - 2, 0)), id_usuario: idusuario, fecha: fecha.replace(/-/g, "/") }
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatosdos);
            this.cargarnotas();
        },
        async desactivaralarma(idnota, idalarma) {
            const api = new PwgsApi();
            let subidadatos = { id_alarma: idalarma};
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatos);
            this.motivo = '';
            this.cargarnotas();
        },
        async cambiarEstadoNota(id_nota, estadoActual) {
            // Aquí puedes manejar la lógica diferente para marcar y desmarcar
            if (estadoActual) {
                this.procesarNotaRevisada(id_nota);
            } else {
                this.procesarNotaNoRevisada(id_nota);
            }
            this.cargarnotas();
        },
        async procesarNotaNoRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 1};
            await api.put('/avisos/revisar-avisos', body);
        },
        async procesarNotaRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 0};
            await api.put('/avisos/revisar-avisos', body);
        }
 
    },
    mounted() {
        this.cargarnotas();
        this.listaUsuarios();
    },
    watch: {
        id() {
            this.cargarnotas();
        },
    }
})
</script>
<style>
.checknotas {
    width: 12px; /* Ajustar según sea necesario */
    height: 12px; /* Ajustar según sea necesario */
    transform: scale(1.5); /* Escalar el checkbox */
    transform-origin: center; /* Asegurarse de que la escala se aplique desde el centro */
    -webkit-transform: scale(1.5); /* Compatibilidad con navegadores WebKit */
    -ms-transform: scale(1.5); /* Compatibilidad con IE */
    margin: 5px; /* Ajustar el margen si es necesario */
}
</style>