<template>
    <div class="alert alert-warning alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> {{$t('general.reclamado')}}</h5>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group " :title="$t('general.fecha') + ' ' + $t('general.reclamacion') ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.fecha')}}</span>
                    </div>
                    <input type="text" class="form-control" v-model="fecha_reclamacion_trabajo"
                        placeholder="dd/mm/aaaa">
                </div>
                <div title="Reclamación pendiente de ser anulada">
                    <span v-if="trabajodatos.reclamacion_anulada_pendiente=='1'" class="badge bg-danger">Pendiente de anulación</span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.precio')}} {{ $t('general.descontar')}}</span>
                    </div>
                    <input type="text" class="form-control" v-model="cantidad_reclamacion_trabajo"
                        :placeholder="$t('general.precio')">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <textarea class="form-control input-sm" v-model="texto_reclamacion_trabajo"
                        :placeholder="$t('general.motivo')" rows="1"></textarea>
                </div>
            </div>
            <div class="col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" @change="anularreclamacion()" v-model="anular" class="custom-control-input" :id="id_anular">
                    <label class="custom-control-label" :for="id_anular">{{ $t('general.anular')}} {{
                        $t('general.reclamacion')}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default ({
    props: ['trabajodatos','guardar'],
    setup() {
        return;
    },
    components: {
        
    },
    data() {
        return {
            reclamados: '',
            fecha_reclamacion_trabajo: '',
            texto_reclamacion_trabajo: '', 
            cantidad_reclamacion_trabajo: '',
            anular: false,
            pendiente: '',
            devuelta: '',
            id_anular:'',
        };
    },
    methods: {
        cargardatos() { 
            this.id_anular = this.$props.trabajodatos.idtrabajo_servicio + "anular";
            this.fecha_reclamacion_trabajo = this.$props.trabajodatos.fecha_reclamacion_trabajo;
            this.texto_reclamacion_trabajo = this.$props.trabajodatos.texto_reclamacion_trabajo;
            this.cantidad_reclamacion_trabajo = this.$props.cantidad_reclamacion_trabajo;
            this.pendiente = this.$props.trabajodatos.reclamacion_anulada_pendiente;
            this.devuelta = this.$props.trabajodatos.reclamacion_anulada_devuelta;
            if (this.$props.trabajodatos.reclamacion_anulada_pendiente == 1) this.anular = true;


        },
        async anularreclamacion() {
            const api = new PwgsApi();
            let subidadatos = '';
            if (this.anular == true) {
                subidadatos = { reclamado_anular: '1' };
            }
            else {
                subidadatos = { reclamado_anular: '0' };
            }
            try {
                await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
                this.anular = false;
            }
            this.$parent.$parent.$parent.togglerecargar();
            this.cargardatos();


        },      
    },
    mounted() {
        this.cargardatos();
    },
    watch: {

        anular() {
            //this.anularreclamacion();
        }

    }
})

</script>