<template>
    <div class="row">
        <div class="col-md-12">
            <div class="callout callout-success">
                <div  class="ribbon_no_cobrada ribbon-top-right"><span>{{$t('general.no cobrada')}}</span></div>
                <h5>{{$t('general.factura')}} <strong>{{datosfactura.numero}}</strong></h5>
                <div class="row">
                    <div class="col-md-4"><input type="text" v-model="datosf.fecha_hoy" class="form-control input-xs" ></div>
                    <div class="col-md-3"><h4>{{ datosf.base_imponible }}&euro;</h4></div>
                    <div class="col-md-5">
                        <button type="button" class="btn btn-default btn-flat" title="Pdf" @click="abrirurl()" ><i class="fas fa-file-invoice"></i></button>
                        <button type="button" class="btn btn-default btn-flat" :title="$t('general.enviar')" ><i class="fas fa-envelope"></i></button>   
                        <button type="button" class="btn btn-default btn-flat" @click="pagarfactura()" :title="$t('general.abonar')"><i class="fas fa-share-square"></i></button>
                        <button type="button" class="btn btn-default btn-flat" @click="eliminarfactura=!eliminarfactura" title="Eliminar"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="row" v-if="eliminarfactura==true">
                  <div class="col-md-12">
                    ¿Desea eliminar la factura?
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <Textarea placeholder="Motivo" v-model="motivoeliminacion"></Textarea>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                          <button type="button" class="btn btn-default btn-flat" @click="eliminar()" title="Eliminar">Eliminar factura</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" >
                    <div class="col-md-12">
                        <br>
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend ">
                            <button type="button" class="btn btn-default dropdown-toggle " data-toggle="dropdown" aria-expanded="false">
                                {{destino}}
                            </button>
                            <ul class="dropdown-menu" style="">
                            <li class="dropdown-item" @click="destino='Compañia'">{{$t('general.companyia')}}</li>
                            <li class="dropdown-item" @click="destino='Cliente'">{{$t('general.cliente')}}</li>
                            </ul>
                            </div>
                            <input type="text" class="form-control " v-model="emails" placeholder="Email">
                            <span class="input-group-append">
                                <button type="button" class="btn btn-info btn-flat" @click="enviarfactura()">{{$t('general.enviar')}}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.ribbon_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_cobrada::before,
.ribbon_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #2980b9;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

.ribbon_no_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_no_cobrada::before,
.ribbon_no_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #c42020;
}
.ribbon_no_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #d32c42;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}


/* top right*/
.ribbon-top-right {
  top: -8px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}


</style>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
  props: ['id', 'servicio', 'factura','togglecargar'],
    data() {
        return {
          mostrarModulo: false,
          datosfactura: '',
          datosf: '',
          datosurl: "",
          url: "",
          emails: '',
          motivo: "Prueba",
          motivoeliminacion:"",
          destino: "Destino",
          eliminarfactura:false,
        }
    },
    methods: {
    
      cargardatos() {
        this.datosfactura = this.$props.factura;
        this.datosf = this.$props.factura.factura;
        this.url = this.$props.factura.url_factura_pdf.slice(5);
        this.datosurl = this.datosurl + this.url + "'";
      },
      abrirurl() {
        window.open(this.datosurl, '_blank');
      },
      async enviarfactura() {
        //PUT  modulos/pwgsapi/index.php/facturas/:id_factura/enviar-factura
        try {
          let subidadatos = { tipo: this.$props.factura.envios_disponibles.email, clave_certificado: this.$props.factura.parametros_sistema_comania, emails: this.emails };
          const api = new PwgsApi();
          await api.put('facturas/' + this.$props.factura.factura.id_factura + '/enviar-factura', subidadatos);
          this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

        }
        catch (error) {
        this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
      }
        

      },
      async pagarfactura() { 
        //PUT  modulos/pwgsapi/index.php/facturas/:id_servicio/enviar-pago-tpv


      },
      async eliminar() {
      //DELETE  modulos/pwgsapi/index.php/facturas/:id_factura/eliminar-factura
      const api = new PwgsApi;
        const params = {motivo:this.motivoeliminacion};
      await api.post('facturas/' + this.$props.factura.factura.id_factura + '/eliminar-factura', params);
      this.$parent.cargardatosFactura();
    }
  },
  mounted() {
    let aux = localStorage.getItem('direccion');
    this.datosurl = "https://" + aux;

    this.cargardatos();
  },
  watch: {
    id() {
      this.cargardatos();
    },
    togglecargar() {
      this.cargardatos();
    }
  }
    
}
</script>