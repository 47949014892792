<template>
    <div class="callout callout-info">
        <h5>{{asegurado.nombre}}</h5>
        <div class="row">
            <div class="col-md-1">
                <i class="fas fa-phone-alt"></i>
            </div>
            <div class="col-md-11">
                <div class="row">
                    <div class="col-md-4" v-if="asegurado.telefono1">
                        <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-xs">{{asegurado.telefono1}}</button>
                    </div>
                    <div class="col-md-4" v-if="asegurado.telefono2">
                            <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-xs">{{ asegurado.telefono2 }}</button>
                    </div>
                    <div class="col-md-4" v-if="asegurado.telefono3">
                        <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-xs">{{ asegurado.telefono2 }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>  

export default ({
        props: ['id', 'asegurado'],

    setup() {     
    },
    methods: {
       llamar : function(){
           window.open("tel:963559516");
       }
    }
})


</script>