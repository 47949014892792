<template>
    <br>
    <br>
    <div class="row">
        <div class="col-12">
            <div class="card card-default">
                <div class="card-header">
                    <h4 class="card-title">{{$t('general.archivos')}}</h4>
                </div>
                <div class="card-body">
                    <div class="row" style="justify-content: space-around;row-gap: 10px;">
                        <Galleria v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :responsiveOptions="responsiveOptions" :value="archivos" :numVisible="7"
                            containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                            <template #item="slotProps">
                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                            </template>
                            <template #thumbnail="slotProps">
                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                            </template>
                        </Galleria>
                        <div v-for="(archivos, index) in archivos" :key="archivos">
                            <div style="display: grid" v-if="archivos.image==''">
                                <i class="fas fa-file fa-8x"></i>
                                <input @blur="handleBlur(archivos.id_documento)" style="width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                            <div v-else-if="archivos.propiedad_cliente!=0">
                                <div class="image-container">
                                    <img style="width: 100px; height: 127px;"  @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                    <img style="width: 100px; height: 127px;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                    <input @blur="handleBlur(archivos.id_documento)" style=" width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                    <div style="width: 350px;"  class="image-zoom-overlay">
                                        <img style="width: 300px; height: 300px;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                        <img style="width: 300px; height: 300px;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                    </div>
                                </div>
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="image-container">
                                    <img style="width: 100px; height: 127px;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                    <img style="width: 100px; height: 127px;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                    <input @blur="handleBlur(archivos.id_documento)" style=" width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                    <div style="width: 350px;" class="image-zoom-overlay">
                                        <img style="width: 300px; height: 300px;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                        <img style="width: 300px; height: 300px;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                    </div>
                                </div>
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <input type="file" @change="uploadFile" ref="file">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Galleria from 'primevue/galleria';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','visita'],
    setup() {
    },
    components: {
        Galleria
    },
     data() {
         return {
            archivos:'',
            descripcion:[],
            activeIndex: 0,
            toggle:false,
            urlglobal: "",
            displayCustom: false,
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
        }
    },
    methods: {
        /*async handleBlur(id){
            
        },*/
        imageClick(index) {
            this.activeIndex = index;
            this.displayCustom = true;
        },
        async pulsado(url){
            const api = new PwgsApi;
            var resp = await api.put(url);
            console.log('respuestaAccion', resp);
            this.$parent.$parent.obtenerVisitas(this.$props.id, this.$props.visita.visita.idvisitador); 
        },
        async uploadFile() {
            const formData = new FormData();
            formData.append(this.$refs.file.files[0].name, this.$refs.file.files[0], this.$refs.file.files[0].name);
            const api = new PwgsApi;
            formData.append("tipo_documento", 'ficherosvisitas');      
            formData.append("id_operario",this.$props.visita.visita.operarios_idoperario);
            formData.append("id_visita",this.$props.visita.visita.idvisitador);
            await api.post('servicios/' + this.$props.id + '/guardar-documento', formData);
           /* for (let i = 0; i < this.$refs.file.files.length; i++) {
                formData.append(this.$refs.file.files[i].name, this.$refs.file.files[i], this.$refs.file.files[i].name);
            }*/
            this.$parent.$parent.obtenerVisitas(this.$props.id, this.$props.visita.visita.idvisitador);   

        },
        cargarDescripciones(){
            for(let im of this.visita.archivos){
                this.descripcion[im.id_documento] = im.descripcion;
            }
        },
        async comprobarSe(){
            const api = new PwgsApi();            
            const se = await api.get('/servicios/'+this.id+'/comunicaciones-ste');
            if(se.comunicaciones!=1){
                var index=0;
                for(var arch of this.visita.archivos){
                    for (let i = arch.acciones.length - 1; i >= 0; i--) {
                        if (arch.acciones[i].nombre === "No visible para el cliente") {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.visita.archivos[index].acciones.splice(i, 1);
                        }
                    }
                    index++;
                }
            }
        },
      
    },
    mounted() {
        this.urlglobal = localStorage.getItem("direccion");
        this.comprobarSe();
        this.archivos = this.visita.archivos;
        this.cargarDescripciones();
    },
})
</script>
<style>
.image-container {
    position: relative;
    display: grid;   
}

.image-zoom-overlay {
    width: 350px;
    position: absolute;
    top: -230px;
    left: -331px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-container:hover .image-zoom-overlay {
    display: flex;
}

.image-zoom-overlay img {
    max-width: 90%;
}

.imgcarr{
    width: 600px;
}

@media screen and (max-width:600px){
    .imgcarr{
    width: 300px;
}
}
</style>
