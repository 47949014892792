<template>
    <div class="row">
        <div class="col-md-12">
            <div class="callout callout-warning">
                <div class="ribbon_cobrada ribbon-top-right"><span>{{$t('general.facturado')}}</span></div>
                <h5>{{$t('general.albaran')}} <strong>{{albaran.albaran.numero_expediente}}</strong></h5>
                <div class="row">
                    <div class="col-md-4"><input type="text" class="form-control input-xs" :value="albaran.albaran.fecha_hoy" readonly></div>
                    <div class="col-md-3"><h4>{{albaran.albaran.total}}&euro;</h4></div>
                    <div class="col-md-5">
                        <button type="button" class="btn btn-default btn-flat" @click="abrirpdf()" ><i class="fas fa-file-invoice"></i></button>
                        <button type="button" class="btn btn-default btn-flat" @click="eliminarfactura=!eliminarfactura" title="Eliminar"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                 <div class="row" v-if="eliminarfactura==true">
                  <div class="col-md-12">
                    ¿Desea eliminar el albarán?
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <Textarea placeholder="Motivo" v-model="motivoeliminacion"></Textarea>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                          <button type="button" class="btn btn-default btn-flat" @click="eliminar()" title="Eliminar">Eliminar factura</button>
                      </div>
                    </div>
                  </div>
                </div>                                
            </div>
        </div>
    </div>
</template>
<style scoped>
/* common */
.ribbon_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_cobrada::before,
.ribbon_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #2980b9;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

.ribbon_no_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_no_cobrada::before,
.ribbon_no_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #c42020;
}
.ribbon_no_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #d32c42;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}


/* top right*/
.ribbon-top-right {
  top: -8px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}


</style>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
props: ['id', 'servicio', 'albaran','togglecargar'],

    data() {
        return {
          mostrarModulo: false,
          datosurl: "",
          url: "",
          motivo: "Prueba",
          motivoeliminacion: "",
          destino: "Destino",
          eliminarfactura: false,
        }
    },
    methods: {
     
      cargardatos() { 
        this.url = this.$props.albaran.url_albaran_pdf.slice(5);
        this.datosurl = this.datosurl + this.url + "'";
      },
      async eliminar() { 
       //DELETE  modulos/pwgsapi/index.php/facturas/:id_factura/eliminar-factura
        const api = new PwgsApi;
        let subidadatos = { motivo: this.motivoeliminacion };
        await api.delete('facturas/' + this.$props.albaran.albaran.id_factura + '/eliminar-factura',subidadatos);
        this.$parent.cargardatosAlbaran();
      },
      abrirpdf() {
        window.open(this.datosurl, '_blank');

      }
  },
  mounted() {
    let aux = localStorage.getItem('direccion');
    this.datosurl = "https://" + aux;
    this.cargardatos();
  },
  watch: {
    togglecargar() {
      this.cargardatos();
    }
  }
}
</script>