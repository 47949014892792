<template>
    <div class="row">
        <div class="col-md-8">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.nombre') }}
            <p class="">
                <strong>{{perito.nombre}}</strong>
            </p>
        </div>        
    </div>
    <div class="row">
        <div class="col-md-4" v-if="perito.telefono1">
            <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }}
            <p class="">
                <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-sm">{{perito.telefono1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.movil1">
            <i class="fas fa-phone-alt"></i> {{ $t('general.movil') }}
            <p class="">
                <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-sm">{{perito.movil1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.email">
            <i class="fas fa-mail-bulk"></i> Email
            <p class="">
                <button type="button" v-on:click='llamar' class="btn btn-block btn-outline-info btn-sm">{{perito.email}}</button>
            </p>
        </div>
    </div>
</template>
<script>  

export default ({
        props: ['id', 'servicio','perito'],

    setup() {     
    },
    data() {
        return {
        }
    },
    methods: {
       llamar : function(){
           window.open("tel:963559516");
       }
    },
    mounted() {
        
    },
})


</script>