<template>
    <div class="row">
        <div class="col-md-4">
            <servicio-datos-siniestro :id="id" :servicio="servicio" :servicioext="servicioext" />
            <servicio-datos-direccion :servicio="servicio" :id="id" :asegurado="asegurado" />
            <servicio-datos-perjudicados :id="id" :servicio="servicio" v-for="perjudicado in perjudicados"
                :perjudicado="perjudicado" :key="perjudicado" />
            <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                        <label for="exampleInputEmail1">{{ $t('general.agregarperjudicado') }}</label>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <input type="text" placeholder="Nombre perjudicado" v-model="nombreperjudicado"
                                class="form-control input-sm">
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-default" @click="agregarperjudicado()">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-6">
                    <servicio-datos-asegurado :servicioext="servicioext" :id="id" :servicio="servicio"
                        :asegurado="asegurado" />
                </div>
                <div class="col-md-6">
                    <servicio-datos-perito :servicio="servicio" :id="id" />

                </div>
            </div>
            <servicio-datos-equipo v-if="equipos" :id="id" />
            <servicio-datos-visitas :id="id" :servicio="servicio" :perjudicados="perjudicados" :asegurado="asegurado" />

        </div>
    </div>
</template>
<script>  
import serviciodatossiniestro from './siniestro/siniestro.vue';
import serviciodatosdireccion from './direccion/direccion.vue';
import serviciodatosperjudicados from './perjudicados/perjudicados.vue'; 
import serviciodatosasegurado from './asegurado/asegurado.vue';
import serviciodatosperito from './perito/perito.vue';
import serviciodatosequipo from './equipos/equipos.vue';

import serviciodatosvisitas from './visitas/visitas.vue';


import { PwgsApi } from '../../../../services/PwgsApi';
export default ({

            props: ['id','servicio','perjudicados','asegurado','servicioext'],

    setup() { 

        
            
    },
       data() {
        return {
            file: '',
            nombreperjudicado: '',
            idaux: '',
            equipos:false,
        }
    },
    mounted(){
        this.comprobarEquipos();
    },
     methods: {
         async comprobarEquipos(){
            const api = new PwgsApi();
            const ia = await api.get('parametros-pwgs/gestion_de_equipos');
            if(ia['gestion_de_equipos']==1){
                this.equipos=true;
            }
         },
         async submitFile(id) { 
            //https://sonia.pwgs.app/modulos/pwgsapi/index.php/servicios/26100/guardar-documento
            const api = new PwgsApi;
            let formData = new FormData();
            formData.append('ficherosvisitas', this.file,this.file.name);

            await api.post('servicios/' + id + '/guardar-documento',formData);
         },
         async handleFileUpload( event ) { 
  
             this.file = event.target.files[0];

         },
         async agregarperjudicado() { 
             const api = new PwgsApi;
             let subidadatos;
             subidadatos = {nombre:this.nombreperjudicado,asegurado: this.$props.asegurado.idcliente,direccion:" ",poblacion:" ",telefono1:" " };
             this.idaux = await api.post('clientes', subidadatos);
             this.enlazarperjudicado();

         },
         async enlazarperjudicado() { 
            //PUT https://sonia.pwgs.app/modulos/pwgsapi/index.php/clientes/28626/modificar-cliente-servicio/17811
             try {
                const api = new PwgsApi;
                let subidadatos = { perjudicado: this.$props.asegurado.idcliente };
                await api.put('clientes/' + this.idaux.id + '/modificar-cliente-servicio/' + this.$props.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$parent.cargardatos(this.$props.id);
             }
               catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

         }
    },
    components: {
        'servicio-datos-siniestro': serviciodatossiniestro,
        'servicio-datos-direccion': serviciodatosdireccion,
        'servicio-datos-perjudicados': serviciodatosperjudicados,
        'servicio-datos-asegurado': serviciodatosasegurado,
        'servicio-datos-perito': serviciodatosperito,
        'servicio-datos-equipo': serviciodatosequipo,

       
        'servicio-datos-visitas': serviciodatosvisitas,
        
        
    },
})


</script>