<template>
        <div class="row">
            <div class="col-md-7">
                <servicio-comunicaciones-form :id="id"/>
            </div>
            <div class="col-md-5">
                <servicio-comunicaciones-lista :mensajes="mensajes" :id="id" :resumen="resumen" @recargarMensajes="cargarmensajes()"/>
            </div>
        </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import serviciocomunicacionesform from './comunicaciones_form.vue';
import serviciocomunicacioneslista from './comunicaciones_lista.vue';
export default ({
            props: ['id'],
    setup() {
        return;
    },
    components: {
        'servicio-comunicaciones-form': serviciocomunicacionesform,
        'servicio-comunicaciones-lista': serviciocomunicacioneslista,
    },
      data() {
        return {
            clientedatos: [],
            titulocliente: '',
            mensajes: '',
        }
    },
    methods: {
        async cargarmensajes() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/mensajes
            const api = new PwgsApi();
            let mensajesaux = [];
            mensajesaux = await api.get('servicios/' + this.$props.id + '/mensajes');
            this.mensajes = mensajesaux.datos;
        },
        
    },
    watch: {
        id() {
            this.cargarmensajes();
         }
    },
    mounted() {
        this.cargarmensajes();
    },
})



</script>
