<template>    
    <Dialog :visible="visible" modal header="Seleccione el trabajo" :style="{width: '1200px'}"  :closable="false">
        <div v-if="isSaving" class="dialog-overlay">
            <i class="fas fa-spinner fa-spin"></i> Guardando...
        </div>
        <div v v-else style="display: flex; gap: 15px;">
            <div style="width: 80%;">
                <!-- Mostrar el spinner si los trabajos están cargando -->
                <div v-if="isLoading" class="loading-spinner">
                    <i class="fas fa-spinner fa-spin"></i> Cargando trabajos...
                </div>
                <Listbox v-model="trabajoSeleccionado" :options="trabajosFiltrados" optionLabel="label" class="w-full md:w-14rem" listStyle="max-height:250px">
                    <template #option="slotProps">
                        <div class="flex align-items-center">                        
                            <div>{{ slotProps.option.codigo }} {{ slotProps.option.nombre }} [{{ slotProps.option.precio }}€]</div>
                        </div>
                    </template>
                </Listbox>           
            </div>
            <div>
                <input style="flex-grow: 1;" v-model="filtro" type="text" placeholder="Filtrar">     
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" @click="$emit('update:visible', false)" class="btn-default" />
        </template>
        
    </Dialog>
</template>


<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
export default {
    props: ['trabajodatos', 'visible'],
    components: {
        Dialog,
        Listbox,
    },
    data() {
        return {
            filtro:'',
            trabajos:[],
            trabajoSeleccionado:'',
            isLoading: false, // Estado para controlar la carga de los trabajos
            isSaving: false,  // Estado para mostrar "loading" al guardar
        };
    },
    mounted() {
        
    },
    computed:{
        trabajosFiltrados(){
            if(this.filtro==''){
                return this.trabajos;
            }else{
                return this.trabajos.filter(t => t.codigo.toLowerCase().includes(this.filtro.toLowerCase())||t.nombre.toLowerCase().includes(this.filtro.toLowerCase()));
            }
        }
    },
    methods: {
        async cargarTrabajos(){
            const api = new PwgsApi;
            this.isLoading = true; // Iniciar el indicador de carga
            try {
                this.trabajos = await api.get('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/baremo');
            } catch (error) {
                alert('Error al cargar los trabajos');
            } finally {
                this.isLoading = false; // Finalizar el indicador de carga
            }
        },
        async guardar(){
            const api = new PwgsApi;
            this.isSaving = true;  
            //console.log('baremoSelec', this.trabajoSeleccionado);
            try{
                await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/modificar-trabajo', { baremos_trabajos_id_trabajo: this.trabajoSeleccionado.idtrabajo });
                this.$emit('update:visible', false)
                this.$parent.$parent.$parent.togglerecargar();

            }
            catch (error) {
                alert(error)
            } finally {
                this.isSaving = false;  // Detener el indicador de guardado
            }
        }
    },
    watch: {
        visible(newVal) {
            //this.localVisible = newVal;
            if (newVal) {
                // Cargar los trabajos cuando el modal se haga visible
                this.cargarTrabajos();
            }
        },
        trabajoSeleccionado(){
            this.guardar();
        }
    },
};
</script>

<style scoped>
.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: #333;
    margin-top: 20px;
}
</style>