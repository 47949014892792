<template>
    <div class="row container-fluid">
        <section class="content-header">
            <div class="row">
                <div class="col-md-12">
                    <h1>{{ $t('general.servicio') }}</h1>
                </div>

            </div>
            <br>
        </section>
        <section class="content" id="contenido" style="width:100%">

            <div class="row">
                <div v-show="mostrarlateral == true" class="col-md-2">
                    <servicio-lateral :estadospendientes="estadospendientes" :miestadopendiente="miestadopendiente"
                        :servicioext="servicioext" :id="id" :servicio="servicio" :tramitador="tramitador"
                        :asegurado="asegurado" :fecha="fecha" :estilo="estilo" :tramitaraux="tramitaraux" />
                </div>
                <div :class="miclase">
                    <div class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active"
                                        @click="mostrarf2();guardarpestanyaactiva('datos')" href="#datos_servicio"
                                        data-toggle="tab">{{$t('general.datos')}}</a></li>
                                <li class="nav-item" v-if="tramitaraux == false"><a class="nav-link"
                                        @click="mostrarf2(); seccioncomunicaciones = true;guardarpestanyaactiva('comunicaciones')"
                                        href="#comunicaciones_servicio"
                                        data-toggle="tab">{{$t('general.comunicaciones')}}</a></li>
                                <li v-if="mostrarplanning==true && tramitaraux == false" class="nav-item"><a
                                        class="nav-link"
                                        @click="comprobartipodano(); mostrarf(); seccionplanning = true;guardarpestanyaactiva('planning')"
                                        href="#planning_servicio" data-toggle="tab">{{ $t('general.planning') }}</a>
                                </li>
                                <li class="nav-item" v-if="tramitaraux == false"><a class="nav-link"
                                        @click="mostrarf2(); seccionpresupuestos = true;guardarpestanyaactiva('presupuestos')"
                                        href="#presupuestos_servicio"
                                        data-toggle="tab">{{$t('general.presupuestos')}}</a></li>
                                <li class="nav-item" v-if="tramitaraux ==false && permitirpedidos == true"><a
                                        class="nav-link"
                                        @click="mostrarf2(); seccionpedidos = true; guardarpestanyaactiva('pedidos')"
                                        href="#pedidos_servicio" data-toggle="tab">{{$t('general.pedidos')}}</a>
                                </li>
                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="mostrarf2(); secciontrabajos = true;guardarpestanyaactiva('trabajos')"
                                        href="#trabajos_servicio" data-toggle="tab">{{$t('general.trabajos')}}</a>
                                </li>

                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="cargarfacturacion(); mostrarf2(); seccionfacturacion = true;guardarpestanyaactiva('facturacion')"
                                        href="#facturacion_servicio" data-toggle="tab">{{$t('general.facturacion')}}</a>
                                </li>
                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="mostrarf2(); seccionadjuntos = true;guardarpestanyaactiva('adjuntos')"
                                        href="#adjuntosemail_servicio" data-toggle="tab">{{
                                        $t('general.adjuntos')}}</a>
                                </li>
                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="mostrarf2(); seccionnotas = true;guardarpestanyaactiva('notas')"
                                        href="#notas_servicio" data-toggle="tab">{{$t('general.notas') }}</a></li>
                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="mostrarf2(); seccionincidencias = true;guardarpestanyaactiva('incidencias')"
                                        href="#incidencias_servicio" data-toggle="tab">{{
                                        $t('general.incidencias')}}</a></li>
                                <li v-if="encuestas && tramitaraux == false" class="nav-item"><a class="nav-link"
                                        @click="mostrarf2(); seccionEncuestas = true;guardarpestanyaactiva('encuestas')"
                                        href="#encuestas_servicio" data-toggle="tab">
                                        Encuestas</a></li>
                                <li v-if="gestorDocsActivado && tramitaraux == false" class="nav-item"><a
                                        class="nav-link"
                                        @click="mostrarf2(); seccionGestor = true;guardarpestanyaactiva('gestor')"
                                        href="#gestor_servicio" data-toggle="tab">
                                        Gestor documentos</a></li>
                                <li class="nav-item" v-if="tramitaraux ==false"><a class="nav-link"
                                        @click="mostrarf2(); seccionInforme = true;guardarpestanyaactiva('informe')"
                                        href="#informe_servicio" data-toggle="tab">Informe fotográfico</a></li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="tab-pane active" id="datos_servicio">
                            <servicio-datos :id="id" :asegurado="asegurado" :servicioext="servicioext"
                                :servicio="servicio" :perjudicados="perjudicados" />
                        </div>
                        <div class="tab-pane" id="comunicaciones_servicio">
                            <comunicaciones v-if="seccioncomunicaciones==true" :id="id" />
                        </div>
                        <div class="tab-pane" id="planning_servicio">
                            <planning :id="id" v-if="seccionplanning==true" :servicio="servicio" />
                        </div>
                        <div class="tab-pane" id="presupuestos_servicio">
                            <presupuestos :id="id" v-if="seccionpresupuestos==true" :servicio="servicio" />
                        </div>
                        <div class="tab-pane" id="pedidos_servicio">
                            <pedidos :parametrosgenerales="parametrosgenerales" :id="id" :servicio="servicio"
                                v-if="seccionpedidos==true" />
                        </div>
                        <div class="tab-pane" id="trabajos_servicio">
                            <trabajosrealizados :id="id" v-if="secciontrabajos==true" :servicio="servicio" />
                        </div>
                        <div class="tab-pane" id="adjuntosemail_servicio">
                            <adjuntosemail :id="id" :servicio="servicio" v-if="seccionadjuntos==true" />
                        </div>
                        <div class="tab-pane" id="facturacion_servicio">
                            <facturacion :ffac="ffac" :id="id" :servicioext="servicioext" :servicio="servicio"
                                v-if="seccionfacturacion==true" />
                        </div>
                        <div class="tab-pane" id="notas_servicio">
                            <notas :id="id" :servicio="servicio" v-if="seccionnotas == true" />
                        </div>
                        <div class="tab-pane" id="incidencias_servicio">
                            <incidencias :id="id" :servicio="servicio" v-if="seccionincidencias == true" />
                        </div>
                        <div class="tab-pane" id="encuestas_servicio">
                            <encuestas :id="id" :servicio="servicio" v-if="seccionEncuestas == true" />
                        </div>
                        <div class="tab-pane" id="gestor_servicio">
                            <gestordocumentos :id="id" :servicio="servicio" v-if="seccionGestor == true" />
                        </div>
                        <div class="tab-pane" id="informe_servicio">
                            <informefotografico :id="id" :servicio="servicio" v-if="seccionInforme == true" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Dialog ref="midialogo" position="bottom" class="midialogo" header="Tramitar Expediente" :style="{ width: '50vw' }"
        :closeOnEscape="false" v-model:visible="visible" closable >
        <template #closeicon ><p @click="cerrarpestaña()">X</p></template>


            <div class="row">
                <p style="background:#80808099;text-align:center"><b>NOTA:</b> {{ servicio.nota_importante}}<br></p>
            </div>
            <div class="row">
                <p>
                    <b>NIF:</b> {{ servicio.asegurado.nif}}<br>
                    <b>Expediente:</b> {{ servicio.codigo_original}}<br>
                    <b>Daños:</b> {{ servicio.danos}}<br>
                    <b>Límites autonomía:</b> {{ servicio.limite_autonomia}}<br>
                    <b>Cobertura:</b> {{ servicio.cobertura}}<br>
                    <b>Contrato Servicio:</b> {{ servicio.contrato}}<br>
                    <b>Num. Poliza:</b> {{ servicio.asegurado.poliza}}<br>
                </p>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <Button style="width:100%" label="Tramitar Expediente" @click="mostrartramitar()"></Button>

                </div>



            </div>
    </Dialog>
    <Dialog ref="dialogobloqueo" class="midialogo" header="Servicio ocupado" :style="{ width: '30vw' }"
        :closeOnEscape="false" v-model:visible="visiblebloqueado">
        <div class="row">
            <p style="text-align:center"><b>Servicio ocupado por el usuario
                    {{servicio.bloqueo.nombre_usuario}} {{servicio.bloqueo.tiempo}}</b><br><br></p>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <Button style="width:80%" @click="asignarmebloqueo()"><i
                        class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;&nbsp;<b>Echar</b></Button>
            </div>

        </div>
    </Dialog>
    <Dialog header="Seleccionar especialidad 1ra visita" modal :style="{ width: '25vw' }"
        v-model:visible="visibleprimeravisita">
        <div class="row">
            <div class="col-md-2"></div>
            <b>Especialidad</b>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <select v-model="especialidadseleccionada" style="width:18rem" class="form-control input-sm">
                <option v-for="especialidades in servicio.autovisita.especialidades"
                    :key="especialidades.idespecialidad" :value="especialidades.idespecialidad">
                    {{especialidades.nombre}}</option>

            </select>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-2"></div>
            <b>Tipo de daño</b>

        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <select v-model="danoseleccionado" style="width:18rem" class="form-control input-sm">
                <option v-for="tipodano in servicio.autovisita.tipos_danyo" :key="tipodano.id_tipo_danyo"
                    :value="tipodano.id_tipo_danyo">{{tipodano.danyo}}</option>

            </select>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-2"></div>
            <b>Envio comunicación</b>

        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <select v-model="accionseleccionada" style="width:18rem" class="form-control input-sm">
                <option value="realizar_llamada">Realizar llamada</option>
                <option value="enviar_sms">Envio SMS</option>
            </select>
        </div>
        <div class="row"><br>&nbsp;</div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <Button style="width:100%" label="Guardar" @click="empezartramitar()"></Button>
            </div>
            <div class="col-md-4"> <Button style="width:100%" label="Cerrar" @click="tramitarexpediente()"></Button>
            </div>
        </div>
    </Dialog>
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../services/PwgsApi';
import serviciolateral from './lateral/lateral.vue';
import serviciodatos from './datos/datos.vue';
import comunicaciones from './comunicaciones/comunicaciones.vue';
import presupuestos from './presupuestos/presupuestos.vue';
import planning from './planning/planning.vue';
import trabajosrealizados from './trabajosrealizados/TrabajosRealizados.vue';
import facturacion from './facturacion/Facturacion.vue';
import pedidos from '../pedidos/PedidosLista.vue';
import adjuntosemail from './adjuntosemail/adjuntosemail.vue';
import notas from './notas/notas.vue';
import encuestas from './encuestas/encuestas.vue';
import gestordocumentos from './gestordocumentos/gestordocumentos.vue';
import incidencias from './incidencias/incidencias.vue';
import Dialog from 'primevue/dialog';
import informefotografico from './informefotografico/informefotografico.vue';

export default ({
        props: ['id'],

    setup() {     
    },
    components: {
        'servicio-lateral': serviciolateral,
        'servicio-datos': serviciodatos,
        'comunicaciones': comunicaciones,
        'presupuestos': presupuestos,
        'planning': planning,
        'encuestas': encuestas,
        'gestordocumentos':gestordocumentos,
        informefotografico,
        trabajosrealizados,
        facturacion,
        pedidos,
        adjuntosemail,
        notas,
        incidencias,
        Dialog,
        
    },
    data() {
        return {
            gestorDocsActivado: false,
            encuestas:false,
            servicio: [],
            perjudicados: [],
            tramitador: [],
            asegurado: [],
            fecha: '',
            ffac: 0,
            mostrarplanning:true,
            mostrar: false,
            visiblebloqueado:false,
            servicioext: '',
            miclase: "col-md-10",
            mostrarlateral: true,
            estadospendientes: [],
            miestadopendiente: '',
            visible: false,
            danoseleccionado:'',
            visitacreada: '',
            especialidadseleccionada:[],
            clasedifuminado:"row",
            visibleprimeravisita: false,
            accionseleccionada: '',
            error: false,
            visitaerror: false,
            tramitaraux: false,
            seccioncomunicaciones:false,
            seccionplanning:false,
            seccionpresupuestos:false,
            seccionpedidos: false,
            secciontrabajos:false,
            seccionadjuntos:false,
            seccionfacturacion:false,
            seccionnotas:false,
            seccionincidencias:false,
            seccionEncuestas:false,
            seccionGestor:false,
            seccionInforme: false,
            parametrosgenerales: { compras: '', gestion_proveedores: '' },
            permitirpedidos: true,
            rutaventana: '',
        }
    },
    methods: {
        async cargarparametros() {
            const api = new PwgsApi();
            const apidos = new PwgsApi();
            let aux = await api.get('parametros-pwgs/gestion_proveedores');
            this.parametrosgenerales.gestion_proveedores = aux.gestion_proveedores;
            let auxdos = await apidos.get('parametros-pwgs/compras');
            this.parametrosgenerales.compras = auxdos.compras;
            if(this.parametrosgenerales.compras != 1 && this.parametrosgenerales.gestion_proveedores != 1){
                this.permitirpedidos = false;
            }
        },
        cerrarpestaña() {
            this.$store.dispatch('quitarRuta', this.rutaventana);
            this.$router.push({ name: 'Escritorio' });


        },
        guardarpestanyaactiva(pestanya) {
            localStorage.setItem("pestanyactivaservicio" + this.$props.id, pestanya);
        },
        cargarpestanyaactiva() {
            let pestanaactiva = localStorage.getItem('pestanyactivaservicio' + this.$props.id);
            if (pestanaactiva == 'datos') {
                this.mostrarf2();
                $('[href="#datos_servicio"]').tab('show');

            }
            if (pestanaactiva == 'comunicaciones') {
                this.mostrarf2();
                this.seccioncomunicaciones = true;
                $('[href="#comunicaciones_servicio"]').tab('show');

            }
            if (pestanaactiva == 'planning') {
                this.mostrarf();
                
                this.seccionplanning = true;
                $('[href="#planning_servicio"]').tab('show');

            }
            if (pestanaactiva == 'presupuestos') {
                this.mostrarf2();
                this.seccionpresupuestos = true;
                $('[href="#presupuestos_servicio"]').tab('show');

            }
            if (pestanaactiva == 'pedidos') {
                this.mostrarf2();
                this.seccionpedidos = true;
                $('[href="#pedidos_servicio"]').tab('show');

            }
            if (pestanaactiva == 'trabajos') {
                this.mostrarf2();
                this.secciontrabajos = true;
                $('[href="#trabajos_servicio"]').tab('show');

            }
            if (pestanaactiva == 'facturacion') {
                this.mostrarf2();
                this.seccionfacturacion = true;
                $('[href="#facturacion_servicio"]').tab('show');

            }
            if (pestanaactiva == 'adjuntos') {
                this.mostrarf2();
                this.seccionadjuntos = true;
                $('[href="#adjuntosemail_servicio"]').tab('show');

            }
            if (pestanaactiva == 'notas') {
                this.mostrarf2();
                this.seccionnotas = true;
                $('[href="#notas_servicio"]').tab('show');

            }
            if (pestanaactiva == 'incidencias') {
                this.mostrarf2();
                 this.seccionincidencias = true;
                $('[href="#incidencias_servicio"]').tab('show');

            }
            if (pestanaactiva == 'encuestas') {
                this.mostrarf2();
                this.seccionEncuestas = true;
                $('[href="#encuestas_servicio"]').tab('show');

            }
            if (pestanaactiva == 'gestor') {
                this.mostrarf2();
                this.seccionGestor = true;
                $('[href="#gestor_servicio"]').tab('show');

            }
            if (pestanaactiva == 'informe') {
                this.mostrarf2();
                this.seccionInforme = true;
                $('[href="#informe_servicio"]').tab('show');

            }
           },
      async cargardatos() {
            //  modulos/pwgsapi/index.php/servicios/:id/datos-basicos
          $('[href="#datos_servicio"]').tab('show');
          this.cargarpestanyaactiva();

            this.tramitaraux = false;
            this.error = false;
            const api = new PwgsApi;
            const datos = await api.get("servicios/" + this.$props.id + '/datos-basicos');
            this.servicio = datos;
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana.includes(ventanaid)) {
                    this.rutaventana = this.$store.getters.ventanas[i];
                    this.$store.getters.ventanas[i].alt = datos.clientes.asegurado.nombre + " || " + datos.clientes.asegurado.direccion;
                }
            }
            document.getElementById("contenido").style.filter = "none";
            document.getElementById("contenido").style.pointerEvents = "auto";
            if (this.servicio.tramitado != 1) { this.visible = true; }
            if (this.servicio.tramitado == 1) { this.visible = false; }
            this.perjudicados = datos.clientes.perjudicados;
            this.tramitador = datos.tramitador.nombre;
            this.asegurado = datos.clientes.asegurado;
            this.fecha = datos.fecha_espera.fecha;
            if(this.servicio.bloqueo){
            if (this.servicio.bloqueo.estado == 0) {
                this.bloquearservicio();
            }
            if (this.servicio.bloqueo.estado == 1) {
                this.advertirbloqueo();
            }
            }
        },
        async comprobarGestorDocs(){
            const api = new PwgsApi();
            const resp = await api.get('parametros-pwgs/gestion_doc');
            if(resp.gestion_doc == 1){
                this.gestorDocsActivado = true
            }
        },
        async comprobarEncuestas(){
            const api = new PwgsApi();
            try {
                await api.get('servicios/'+this.$props.id+'/encuestas');
                this.encuestas = true;
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                console.error(error);
            }
        },
      tramitarerror(){
          this.tramitarexpediente();
          this.visibleprimeravisita = false;
        },
        async comprobartipodano() {//error diego mirar
            
           const api = new PwgsApi();
            let aux = await api.get('servicios/' + this.$props.id);
            console.log("asddd123", aux.datos.tipo_danyo_servicio);
            if (aux.datos.tipo_danyo_servicio){
                this.mostrarf();
            }
            else {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: "Debe rellenar el tipo de daño para mostrar el planning", life: 5000 });
                $('[href="#datos_servicio"]').tab('show');
                this.mostrarf2()


            }
      },
      async empezartramitar() {
        const api = new PwgsApi()
        let subidadatos = { id_tipo_dano: this.danoseleccionado, id_especialidad: this.especialidadseleccionada };
            try {
                this.visitacreada = await api.post('servicios/' + this.$props.id + '/configurar-autovisita', subidadatos);
                let subidadatosdos = { id_tipo_dano: this.danoseleccionado, id_especialidad: this.especialidadseleccionada, id_visita: this.visitacreada, accion: this.accionseleccionada }

                try {
                    await api.put('servicios/' + this.$props.id + '/enviar-autovisita', subidadatosdos);
                    this.tramitarexpediente();

                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }

            }           
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                this.error = true;
            }
        },
        activarPestana(pestana) {
            // Desactivamos todas las pestañas.
            $('.nav-link').removeClass('active');
            // Activamos la pestaña deseada.
            $(`[href="#${pestana}_servicio"]`).addClass('active');
            // Desactivamos todas las tab-pane.
            $('.tab-pane').removeClass('active');
            // Activamos la tab-pane deseada.
            $(`#${pestana}_servicio`).addClass('active');
        },
        mostrartramitar() {
            if (this.servicio.autovisita && this.servicio.autovisita.autovisita_obligatoria == 1) {
                this.visibleprimeravisita = true;
            }
            else {
                this.tramitarexpediente();
             }
        },
        async tramitarvisita() {
            //PUT index.php/servicios/:id/enviar-autovisita
            const api = new PwgsApi();
            let subidadatos = { id_tipo_dano: this.danoseleccionado, id_especialidad: this.especialidadseleccionada, id_visita: this.visitacreada, accion: this.accionseleccionada }
            try{
                await api.put('servicios/' + this.$props.id + '/enviar-autovisita', subidadatos);

            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            
            }
        },
        async tramitarexpediente() {
            
            const api = new PwgsApi();
            try{
                await api.put('servicios/' + this.$props.id + '/tramitar');
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio tramitado correctamente', life: 2000 });
                this.visible = false;
                this.visibleprimeravisita = false;
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async obtenerEstadosPendientes() {
            //GET  modulos/pwgsapi/index.php/tipos-estado-pendiente
            const api = new PwgsApi();
            let aux = await api.get('tipos-estado-pendiente');
            this.estadospendientes = aux.datos;
        },
        async cargarEstadoPendiente() {
         //GET modulos/pwgsapi/index.php/tipos-estado-pendiente/:id
            const api = new PwgsApi();
            let aux = await api.get('tipos-estado-pendiente/' + this.$props.id);
            this.miestadopendiente = aux.datos;

        },
        async configurarAutovisita() {
            const api = new PwgsApi();
            let subidadatos = { id_tipo_dano: this.danoseleccionado, id_especialidad: this.especialidadseleccionada };
            try{
                this.visitacreada = await api.post('servicios/' + this.$props.id + '/configurar-autovisita', subidadatos);

            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.visitaerror = true;

        },
     
        async cargarmasdatos() {
            const api = new PwgsApi();
            this.servicioext = await api.get("servicios/" + this.$props.id);
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreservicio = "Servicio: " + this.servicioext.datos.codigo;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana.includes(ventanaid)) {
                    this.$store.getters.ventanas[i].name = nombreservicio;
                }
            }
            
            if(this.servicioext.datos.estado=="finalizado" || this.servicioext.datos.estado=="eliminado"){
                this.mostrarplanning = false;
            }
        },        
        cargarfacturacion() { 
            this.ffac = this.ffac + 1;
        },
        mostrarf() {
            this.mostrar = true;
            this.mostrarlateral = false;
            this.miclase = "col-md-12";
        },
        mostrarf2() {
            this.mostrarlateral = true;
            this.miclase = "col-md-10";
        },
        async bloquearservicio() {
            const api = new PwgsApi();
            await api.put('servicios/' + this.$props.id + '/abrir');

        },
        advertirbloqueo() {
            let usuario = localStorage.getItem('tramitador');
            if (usuario != this.servicio.bloqueo.nombre_usuario) {
                document.getElementById("contenido").style.filter = "blur(4px)";
                document.getElementById("contenido").style.pointerEvents = "none";
                this.usuarioserviciobloqueado = this.servicio.bloqueo.nombre_usuario;
                this.visiblebloqueado = true;
            }
        },
        async asignarmebloqueo() {
            const api = new PwgsApi();
            let subidadatos = {echar:1};
           await api.put('servicios/' + this.$props.id + '/abrir',subidadatos);
            document.getElementById("contenido").style.filter = "none";
            document.getElementById("contenido").style.pointerEvents = "auto";
            this.visiblebloqueado = false;
        }
    },
    created(){
            this.comprobarEncuestas();
            this.comprobarGestorDocs();
        },
    activated() {
        this.mostrarplanning = true;
        this.cargarmasdatos();

        this.cargardatos(this.$props.id);
        this.obtenerEstadosPendientes();
        this.cargarEstadoPendiente();
        this.cargarparametros();
        let tipoaux = localStorage.getItem("tiposerv");
        if(tipoaux == 'solicitud'){
            console.log('essolicitud');
            this.mostrarf2();
            this.seccioncomunicaciones = true;
            this.guardarpestanyaactiva('comunicaciones');
            this.activarPestana('comunicaciones');
            localStorage.setItem("tiposerv", '');
        }
        if(tipoaux == 'norealizados'){
                console.log('esnorealizado');
                this.mostrarf2();
                this.seccionnotas = true;
                this.guardarpestanyaactiva('notas');
                this.activarPestana('notas');
                localStorage.setItem("tiposerv", '');
            }
    },
    watch: {
        id() {
            document.getElementById("contenido").style.filter = "blur(4px)";
            document.getElementById("contenido").style.pointerEvents = "auto";
            this.mostrarplanning = true;
            if(this.$props.id){
            this.cargarmasdatos();
            this.cargardatos(this.$props.id);
            this.obtenerEstadosPendientes();
            this.cargarEstadoPendiente();
            this.mostrarf2();
                this.cargarpestanyaactiva();

            }
            let tipoaux = localStorage.getItem("tiposerv");
            if(tipoaux == 'solicitud'){
                console.log('essolicitud');
                this.mostrarf2();
                this.seccioncomunicaciones = true;
                this.guardarpestanyaactiva('comunicaciones');
                this.activarPestana('comunicaciones');
                localStorage.setItem("tiposerv", '');
            }
            if(tipoaux == 'norealizados'){
                console.log('esnorealizado');
                this.mostrarf2();
                this.seccioncomunicaciones = true;
                this.guardarpestanyaactiva('notas');
                this.activarPestana('notas');
                localStorage.setItem("tiposerv", '');
            }
        },
        visible() {
            if (this.visible == true) {
                this.clasedifuminado = "row difuminado";
                this.tramitaraux = true;
            }
            if(this.visible == false){
                this.clasedifuminado = "row";
                this.tramitaraux = false;
            }
        },
        $route() {
            console.log("hola123", this.$props.id);
            this.visible = false;
            this.visiblebloqueado = false;
        }
    }
})
</script>
<style>

.difuminado{
        filter: blur(6px);
            pointer-events: none;
            -webkit-user-select: none;
                /* Safari */
            -ms-user-select: none;
                /* IE 10 and IE 11 */
            user-select: none;
                /* Standard syntax */
}
/*.midialogo .p-dialog-header-icons{
    visibility: hidden;
}**/
</style>