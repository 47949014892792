<template>
    <div class="row">
        <div class="col-md-7">
            <adjuntos :id="id" :servicio="servicio" :visitas="visitas" />
        </div>
        <div class="col-md-5">
            <email :id="id" :servicio="servicio" :visitas="visitas" :archivosadjuntar="archivosadjuntar" />
        </div>
    </div>
</template>
<script>
import adjuntos from './adjuntos.vue';
import email from './email.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio'],
    setup() {
        return;
        
    },
    components: {
        adjuntos,
        email
    },
    data() {
        return {
            visitas: [],
            archivosadjuntar:[],
        }
    },
    methods: {
        async cargarvisitas() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            this.visitas = [];
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/' + this.$props.id + '/visitas');
        },
        adjarchivo(arch) {
            this.archivosadjuntar = arch;
        }
    },
    watch: {
        id() {
            this.cargarvisitas();

        }
    },
    mounted() {
        this.cargarvisitas();

    },
})



</script>