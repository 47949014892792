<template>
    <div class="card card-widget widget-user-2">
        <!-- Add the bg color to the header using any of the bg-* classes -->
        <div class="widget-user-header bg-success"  style="border-bottom-left-radius:.25rem;border-bottom-right-radius:.25rem">
            <div class="widget-user-image">
                <i class="fas fa-user-edit" style="font-size:xxx-large;position:absolute"></i>

            </div>
            <!-- /.widget-user-image -->
            <h3 v-if="editartramitador==false" @click="editartramitador=true" @keydown.esc="cerrardialogo()"
                class="widget-user-username pointer">{{tramitador}}</h3>
            <h3 v-if="editartramitador == false && tramitador.length<1" @click="editartramitador = true" @keydown.esc="cerrardialogo()"
                class="widget-user-username pointer">...</h3>
            <h5 class="widget-user-desc">Tramitador</h5>
            <Dropdown style="width:-moz-available" @keydown.esc="cerrardialogo()" :options="listadotramitadores"
                option-label="nombre" @change="cambiartramitador()" v-model="tramitadorseleccionado"
                v-if="editartramitador==true"></Dropdown>
        </div>
    </div>

</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';

export default ({
    props: ['id', 'tramitador'],
    setup() {
    },
    data() {
        return {
            tramitadorseleccionado: [],
            listadotramitadores: [],
            editartramitador: false,
        };
    },
    methods: {
        async cambiartramitador() {
            //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
            const api = new PwgsApi();
            let subidadatos = { tramitador: this.tramitadorseleccionado.id };
            try{
                await api.put('servicios/' + this.$props.id + '/modificar-servicio', subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

            }
            this.editartramitador = false;
            this.$parent.$parent.cargardatos()
            
        },
        async listartramitadores() {
            const api = new PwgsApi();
            let aux = await api.get('usuarios/gestion');
            this.listadotramitadores = aux.datos;
        },
        cerrardialogo() {
            this.editartramitador = false;
        }
    },
    components: {
        Dropdown
    },
    mounted() {
       
        this.listartramitadores();
     
    },
    })
</script>
<style>
.pointer {
    cursor: pointer !important;
}

</style>
