<template>
    <div class="row">
        <div class="col-md-5">
            <div class="input-group " :title="$t('general.Base imponible')">
                <div class="input-group-prepend" >
                    <span class="input-group-text" style="min-width:120px" >{{ $t('general.total') + ' ' + $t('general.a cliente') }}</span>
                </div>
                <input type="text" class="form-control" :value="ttales.cliente_base + '&euro;'" readonly >
                <input type="text" class="form-control" :value="facturarcliente + '&euro;'" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
                <div class="input-group-append">
                    <button type="button" class="btn btn-info btn-flat" @click="mostrarTotalesAmpliados">
                        <i class="fas fa-caret-down"></i>
                    </button>
                </div>
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px">IVA 21%</span>
                </div>
                <input type="text" class="form-control" :value="ttales.cliente_iva + '&euro;'" readonly>
                <input type="text" class="form-control" :value="facturariva + '&euro;'"  readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px">{{ $t('general.total') }}</span>
                </div>
                <input type="text" class="form-control" :value="ttalesiva + '&euro;'" readonly>
                <input type="text" class="form-control" :value="facturartotal + '&euro;'" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
            </div>
            <div class="input-group totales_ampliado">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="min-width:120px"></span>
                </div>
                <input type="text" class="form-control" value="Total Expediente" readonly>
                <input type="text" class="form-control" readonly :value="$t('general.para facturar')">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group " :title="$t('general.Base imponible')">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ $t('general.total') + ' ' + $t('general.coste') }}</span>
                </div>
                <input type="text" :value="ttales.operario + '&euro;'" class="form-control" readonly>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{$t('general.rentabilidad')}}</span>
                </div>
                <input type="text" class="form-control" v-model="result" readonly>
                <input type="text" class="form-control" v-model="facturarrentabilidad" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props:['id','servicio','recargar','totalcliente','totaloperario','totalrent'],
    setup() {
        return;
    },
     data() {
        return {
            totalop:'',
            totalcli: '',
            rent: '',
            iva: '',
            totalescliente: '',
            totalesrentabilidad: '',
            totalesoperario: '',
            operarios:'',
            total: '',
            facturarcliente: '',
            facturaroperario: '',
            facturarrentabilidad: '',
            facturariva: '',
            facturartotal: '',
            ttales: '',
            ttalesiva: '',
            result: '0',
        };
    },
    methods: {
        mostrarTotalesAmpliados(){
            $(".totales_ampliado").toggle();
        },
        async cargarmasdatos() {
            //GET  modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi();
            this.operarios = await api.get('trabajos/' + this.$props.id + '/trabajos-servicio');
            this.ttales = this.operarios.totales;
            this.ttalesiva = +this.ttales.cliente_base + +this.ttales.cliente_iva;            
            this.facturarcliente = '';
            this.facturariva = '';
            this.facturaroperario = '';
            for (let oper in this.operarios.operario) {
                if (this.operarios.operario[oper].Cliente) {
                    for (let i = 0; i < this.operarios.operario[oper].Cliente.trabajos.length; i++) {
                        if (this.operarios.operario[oper].Cliente.trabajos[i].datos.facturar_trabajo == "SI") {
                            this.facturarcliente = +this.facturarcliente + +this.operarios.operario[oper].Cliente.trabajos[i].datos.precio;
                        }
                     }
                }
                if (this.operarios.operario[oper].Compañia) {
                    for (let i = 0; i < this.operarios.operario[oper].Compañia.trabajos.length; i++) {
                        if (this.operarios.operario[oper].Compañia.trabajos[i].datos.facturar_trabajo == "SI") {
                            this.facturarcliente = +this.facturarcliente + +this.operarios.operario[oper].Compañia.trabajos[i].datos.precio;
                             }
                        }
                    }
                
      
                if (this.operarios.operario[oper]._) {
                    for (let i = 0; i < this.operarios.operario[oper]._.trabajos.length; i++) {
                        if (this.operarios.operario[oper]._.trabajos[i].datos.facturar_trabajo == "SI") {
                            this.facturarcliente = +this.facturarcliente + +this.operarios.operario[oper]._.trabajos[i].datos.precio;
                            }
                        }
                }
            }
           // this.facturarcliente = +this.facturarcliente.toFixed(2);
            this.facturarrentabilidad = Math.round(((this.facturarcliente - this.facturaroperario) * 100).toFixed(2) / this.facturarcliente) + "%";
            this.facturariva = ((+this.facturarcliente) * (0.21)).toFixed(2);
            this.facturartotal = (+this.facturarcliente + +this.facturariva).toFixed(2);
            this.result = this.ttales.beneficio.porcentaje + '%';
        },
    },
    mounted (){
        $(".totales_ampliado").hide();
        this.cargarmasdatos();
    },
    watch: {
        id() { 
            $(".totales_ampliado").hide();
            this.cargarmasdatos();
        },
        recargar() {
            this.cargarmasdatos();
        }
    }
})
</script>
