<template>
    <div class="card card-primary card-outline">
        <div class="card-body box-profile">
            <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item">
                    <label>
                        Estado pendiente de
                    </label>
                    <Dropdown class="esppendiente" scrollHeight="400px" style="width:-moz-available" @click="permitircambio()" @change="observacionesObligatorias()" attach v-model="estadopenselec"
                        :options="estadospendientes" optionValue="id_especialidad_pendiente" filter
                        optionLabel="nombre_especialidad_pendiente" placeholder="Estado pendiente">
                    </Dropdown>
                    <div v-if="mostrardescripcion == true">
                        <p></p>
                        <label>
                            Descripcion del estado
                        </label>
                        <Textarea rows="4" @keydown.esc="mostrardescripcion=false"
                            v-model="descripcioncambioestado"></Textarea>
                        <p></p>
                        <Button @click="actualizarEstadoPendienteConObservaciones()">Guardar</Button>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" @change="modificarConflictivo()"
                            id="conflict" v-bind:true-value="1" v-bind:false-value="0" v-model="conflictivo">
                        <label for="conflict" class="custom-control-label">Conflictivo</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" @change="modificarUrgente()" id="urgented"
                            v-bind:true-value="1" v-bind:false-value="0" v-model="urgente">
                        <label for="urgented" class="custom-control-label">Urgente</label>
                    </div>
                </li>
                <li class="list-group-item">
                    <label class="labelmostrar" @click="mostrarhistorial= !mostrarhistorial">
                        Historial estados <i class="fa fa-eye" aria-hidden="true"></i>
                    </label>
                    <div v-if="mostrarhistorial==true" class="scroll">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>{{ $t('general.fecha') }}</th>
                                    <th>{{ $t('general.usuario') }}</th>
                                    <th>Estado</th>
                                    <th>Observaciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="historial in servicio.especialidad_pendiente.seguimiento"
                                    :key="historial.id_seguimiento">
                                    <td>{{ historial.fecha }}</td>
                                    <td>{{ historial.usuario }}</td>
                                    <td>{{ historial.estado }}</td>
                                    <td>{{ historial.detalle }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>

                <li class="list-group-item">
                    <label>
                        {{ $t('general.Tipo Daño') }}
                    </label>
                    <Dropdown style="width:-moz-available" @change="cambiartipodano()" attach v-model="selected"
                        :options="tipos_danyo" filter placeholder="Tipos daño">
                    </Dropdown>
                </li>
                <li class="list-group-item"
                    v-if="this.$props.servicioext.datos.fecha_asignacion_companyia_servicio != '0'">
                    <b>Asignación</b>
                    <p class="float-right" style="color:rgb(0, 123, 255)">{{ fecha_asigna }}</p>
                </li>
                <!-- <li class="list-group-item">
                    <b>Última visita</b> <a class="float-right">15/12/2021</a>
                </li>-->
                <li class="list-group-item">
                    <b>Sin actualizar</b>
                    <p class="float-right " style="color:rgb(0, 123, 255)">{{fecha_actualiza}}</p>
                </li>
                <li class="list-group-item">
                    <b>Fecha Espera</b>
                    <p v-if="editar==false" @click="editar=true" class="float-right puntero"
                        style="color:rgb(0, 123, 255)">{{ fecha_espera }}</p>
                    <Calendar @keydown.esc="editar=false" @date-select="modificarFechaEspera()" v-model="fecha_espera"
                        class="float-right" dateFormat=" dd/mm/yy" v-if="editar==true"></Calendar>
                </li>
                <li class="list-group-item">
                    <label class="labelmostrar" @click="mostrarExpedientes= !mostrarExpedientes">
                        Expedientes relacionados <i class="fa fa-eye" aria-hidden="true"></i>
                    </label>
                    <div v-if="mostrarExpedientes==true" class="scroll">
                        <table class="table table-sm">
                            <tbody>
                                <tr v-for="siniestro in servicio.siniestros_relacionados" :key="siniestro.id_servicio">
                                    <td>{{ siniestro.codigo }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ul>
        </div>
    </div>



</template>

<script>
import Textarea from 'primevue/textarea';
import { PwgsApi } from '../../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
export default ({
    props: ['id', 'servicio', 'fecha', 'servicioext','estadospendientes','miestadopendiente'],
    components: {
        Dropdown,Calendar,Textarea
    },
    setup() {
        return; 
    },
    data() {
        return {
            tipos:[],
            selected: '',
            date: '',
            fecha_actualiza: '',
            fecha_espera: '',
            fecha_asigna: '',
            tipos_danyo: [],
            estadopenselec: '',
            mostrarhistorial:false,
            mostrarExpedientes:false,
            servicioaux:[],
            conflictivo: '',
            urgente: '',
            editar: false,
            pausado: '',
            descripcioncambioestado:'',
            mostrardescripcion:false,
            servicioauxiliar: { especialidad_pendiente: { seguimiento: { idseguimiento: '' } } },

        }
    },

        methods: {
            async cargarTipos(){
                this.descripcioncambioestado = '';
                const api = new PwgsApi();
                let aux = await api.get("servicios/" + this.id + '/datos-servicio');
                this.servicioaux = aux.datos;
            },
            async cargardatos(id) {
                this.descripcioncambioestado = '';
                const api = new PwgsApi();
                let aux = await api.get("servicios/" + id);
                this.servicioaux = aux.datos;             
                
                this.estadopenselec = '';
                this.fecha_actualiza = new Date(this.servicioaux.fecha_actualiza).toLocaleDateString('es');
                this.fecha_espera = new Date(this.servicioaux.fecha_espera).toLocaleDateString('es');
                this.fecha_asigna = new Date(this.servicioaux.fecha_crear_servicio).toLocaleDateString('es');
                this.selected = this.servicioaux.tipo_danyo_servicio;
                this.estadopenselec = this.servicioaux.id_especialidad_asignada_servicio;
                this.urgente = this.servicioaux.urgente_servicio;
                this.pausado = this.servicioaux.pausado;
                
                
            },
        async cargartiposdanyo() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-dano');
            const aux = datos.datos;
            for (let i = 0; i < aux.length; i++){
                this.tipos_danyo.push(aux[i].danyo);
            }
            },
            async cambiartipodano() {
                const api = new PwgsApi();
                let subidadatos = {tipo_dano:this.selected}
                try {
                    await api.put('servicios/' + this.$props.id + '/modificar-servicio', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Tipo de daño guardado correctamente', life: 2000 });

                    if (this.$props.servicio.campos_obligatorios.tipo_danyo_servicio == 1) {
                        localStorage.setItem('permitirtipodanyo' + this.$props.id, "true");
                        window.dispatchEvent(new CustomEvent('guardadotipodanyo'));
                    }

                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

                }
                this.$parent.$parent.cargardatos(this.$props.id);
                this.$parent.$parent.cargarmasdatos();
            },
        
            async actualizarEstadoPendienteConObservaciones() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-estado-pendiente
                const api = new PwgsApi();
                if (this.descripcioncambioestado.length > 0) {
                let subidadatos = { id_esp_pend: this.estadopenselec,observaciones:this.descripcioncambioestado }
               try{
                    await api.put('servicios/' +this.$props.id+'/modificar-estado-pendiente',subidadatos);
                   this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Estado guardado correctamente', life: 2000 });
                   this.mostrardescripcion = false;
                   this.cargardatos();
                   this.$parent.$parent.cargardatos();

                       localStorage.setItem('permitir' + this.$props.id, "true");
                       window.dispatchEvent(new CustomEvent('guardadoestadopendiente'));
                   


               }
               catch (error) {
                   this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

               }
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: "Debe rellenar la descripcion", life: 5000 });

                }
            },
            observacionesObligatorias() {
                if (this.$props.servicio.campos_obligatorios.especialidad_pendiente_observaciones==1) {
                    this.mostrardescripcion = true;
                    
                }
                else {
                    this.actualizarEstadoPendiente();
                }
            },
            permitircambio() {
                if (this.$props.servicio.campos_obligatorios.especialidad_pendiente_observaciones != 1) {


                    localStorage.setItem('permitir' + this.$props.id, "true");
                    window.dispatchEvent(new CustomEvent('guardadoestadopendiente'));
                }
            },
            async actualizarEstadoPendiente() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-estado-pendiente
                const api = new PwgsApi();
                    let subidadatos = { id_esp_pend: this.estadopenselec }
                    try {
                        await api.put('servicios/' + this.$props.id + '/modificar-estado-pendiente', subidadatos);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Estado guardado correctamente', life: 2000 });
                        this.mostrardescripcion = false;
                        this.cargardatos();
                        localStorage.setItem('permitir' + this.$props.id, "true");
                        window.dispatchEvent(new CustomEvent('guardadoestadopendiente'));
                        

                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

                    }
               
            },
           async modificarUrgente() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
               let subidadatos = { urgente: this.urgente };
               const api = new PwgsApi();
               try{
                   await api.put("servicios/" + this.$props.id + "/modificar-servicio", subidadatos);
                   this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Estado urgente guardado correctamente', life: 2000 });
                   this.cargardatos(this.$props.id);
               }
               catch (error) {
                   this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

               }
            },
            async modificarConflictivo() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
               let subidadatos = { conflictivo: this.conflictivo };
               const api = new PwgsApi();
               try{
                   await api.put("servicios/" + this.$props.id + "/modificar-servicio", subidadatos);
                   this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Estado conflictivo guardado correctamente', life: 2000 });
                   this.cargardatos(this.$props.id);
               }
               catch (error) {
                   this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

               }
            },
           async  modificarPausado() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
               let subidadatos = { pausado: this.pausado };
               if (this.pausado == "SI") {
                   subidadatos.pausado = 1;
               }
               const api = new PwgsApi();
               try{
               await api.put("servicios/" + this.$props.id + "/modificar-servicio", subidadatos);
               this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio pausado correctamente', life: 2000 });
                   this.cargardatos(this.$props.id);
               }
               catch (error) {
                   this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

               }

           },
            async modificarFechaEspera() {
                //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
                const api = new PwgsApi();
                let auxespera = new Date(this.fecha_espera).addHours(4).toISOString().split('T')[0];
                //let auxesperahora = new Date(this.fecha_espera).toISOString().split('T')[1].split('.')[0];
                let subidadatos = { fecha_espera: auxespera};
                try{
                await api.put("servicios/" + this.$props.id + "/modificar-servicio", subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Fecha guardada correctamente', life: 2000 });
                this.editar = false;
                localStorage.setItem('permitirfechaespera' + this.$props.id, "true");
                window.dispatchEvent(new CustomEvent('guardadofechaespera'));
                this.cargardatos(this.$props.id);
                
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

                }
            },
         
    },

    mounted() {
        
        this.cargardatos(this.$props.id);
        this.cargartiposdanyo();
    },
    activated() {

        
  

    },
 
    watch: {        
        id() {
            
            this.selected = this.servicio.tipo_dano;
            this.cargardatos(this.$props.id);
        },
        servicio() {
          
        
            this.servicioaux = this.servicio;
            this.conflictivo = this.servicio.asegurado.conflictivo;
            if (this.$props.servicio.campos_obligatorios.especialidad_pendiente == 1) {

                localStorage.setItem('permitir' + this.$props.id, "false");

            }
            else {
                localStorage.setItem('permitir' + this.$props.id, "true");
            }
            if (this.$props.servicio.campos_obligatorios.tipo_danyo_servicio == 1) {
                localStorage.setItem('permitirtipodanyo' + this.$props.id, "false");
            }
            else {
                localStorage.setItem('permitirtipodanyo' + this.$props.id, "true");
            }
            if (this.$props.servicio.campos_obligatorios.fecha_espera == 1) {
                localStorage.setItem('permitirfechaespera' + this.$props.id, "false");
            }
            else {
                localStorage.setItem('permitirfechaespera' + this.$props.id, "true");
            }
        },
    }
})
</script>
<style>
.puntero {
    cursor: pointer !important;

}
.scroll{
    overflow: scroll;
}
.labelmostrar{
    cursor: pointer !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    color: #004794;
    font-weight: bold;
}
</style>