<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-calendar-week mr-2"></i>{{ $t('general.visitas') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0 table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>info</th>
                        <th>{{ $t('general.fecha') }}</th>
                        <th width="25%">{{ $t('general.operario') }}</th>
                        <th>{{ $t('general.estado') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-for="visita in visitas" :key="visita.visita.idvisitador">
                    <tr @click="mostrarvisita = true; handleRowClick($event, visita.visita.idvisitador)" :data-expanded="visita.visita.idvisitador === expandidaId">
                        <td ><i @mouseover="infoevento($event, visita.visita.datos.descripcion_visitadores)" @mouseleave="closeInfo()" class="fa fa-info-circle" aria-hidden="true"></i></td>
                        <td>{{ formato(visita.visita.fecha) }} {{ visita.visita.hora.split(':')[0] }}:{{ visita.visita.hora.split(':')[1] }}</td>
                        <td ><p style="width: fit-content;" @mouseover="toggle($event, visita)" @mouseleave="closeToggle()">{{ visita.visita.nombre_operario }}</p></td>
                        <td>
                            <span v-if="visita.visita.realizado_operario == ''" class="badge bg-success">Activa</span>
                            <span v-else class="badge bg-danger">Finalizada</span>
                        </td>
                        <td v-if="mostrarBotones[visita.visita.idvisitador]">
                            <div class="d-flex flex-wrap">
                                <div class="btn-group">
                                    <button v-if="visita.visita.realizado_operario == ''" @click.stop="eliminarvisita(visita)" title="Eliminar visita" class="btn btn-default ">
                                        <i class="fas fa-times" style="color:red;"></i>
                                    </button>                                                                     
                                    <button v-if="mostrarPresupuesto" type="button" @click.stop="mostrarpresupuestos(visita)" class="btn btn-default " :style="'background:' + colorbotonpresup"  title="Presupuesto">
                                        <i class="far fa-folder-open"></i>
                                    </button>
                                    <button type="button" v-if="visita.visita.realizado_operario == '' || modificarcitasfinalizadas"  :style="'background:' + colorbotondos" @click.stop="mostrarEditar(visita)" class="btn btn-default " title="Modificar visita">
                                        <i class="far fa-edit"></i>
                                    </button>
                                    <button v-if="visita.visita.realizado_operario == ''" type="button" class="btn btn-default " @click="mostrarFinalizarBtn(visita)" :style="'background:' + colorbotoncuatro" title="Finalizar Visita">
                                        <i class="fas fa-check"></i>
                                    </button>
                                    
                                    <div class="btn-group open" style="width: 60px; margin: 0;">
                                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu1">
                                            <i class="fas fa-share-square"></i>
                                        </button>
                                        <ul id="dropdownMenu1" class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                            <li class="dropdown-item"><a href="#" @click.stop="parteoperarioemail(visita)">{{ $t('general.enviarfichaemail') }}</a></li>
                                            <li class="dropdown-divider"></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="smsoperario(visita)">{{ $t('general.smsaloperario') }}</a></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="smsasegurado(visita)">{{ $t('general.smsalasegurado') }}</a></li>
                                            <li class="dropdown-item" v-if="ste"><a href="#" @click="steasegurado(visita)">{{ $t('general.firmasteemail') }}</a></li>
                                        </ul>
                                    </div>
                                    <div v-if="visita.fichas_operario.MULTIASISTENCIA_WS" class="btn-group open" style="width: 50px; margin: 0;">
                                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu2">
                                            <i class="far fa-file"></i>
                                        </button>
                                        <ul id="dropdownMenu2" @click.stop="" class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            <li class="dropdown-item"><a href="#" @click.stop="abrirventana(visita)">Parte Genérico</a></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="multi1(visita)">{{ $t('multiasesistencia 1') }}</a></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="multi2(visita)">{{ $t('multiasesistencia 2') }}</a></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="multi3(visita)">{{ $t('multiasesistencia 3') }}</a></li>
                                        </ul>
                                    </div>
                                    <div v-else-if="visita.fichas_operario.MAPFRE" class="btn-group open" style="width: 50px; margin: 0;">
                                        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu2">
                                            <i class="far fa-file"></i>
                                        </button>
                                        <ul id="dropdownMenu2" @click.stop="" class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            <li class="dropdown-item"><a href="#" @click.stop="abrirventana(visita)">Parte Genérico</a></li>
                                            <li class="dropdown-item"><a href="#" @click.stop="mapf(visita)">Mapfre</a></li>
                                        </ul>
                                    </div>
                                    <button v-else type="button" class="btn btn-default " @click.stop='abrirventana(visita)' title="Parte Genérico">
                                        <i class="far fa-file"></i>
                                    </button>
                                </div>
                            </div>            
                        </td>
                    </tr>
                    <tr v-if="mostrarvisita==true" v-show="visita.visita.idvisitador === expandidaId" class="expandable-body">
                        <td colspan="5">
                            <servicio-datos-visitas-visita v-if="mostrarvisita==true"  :visita="visita" :servicio="servicio" :id="id"/>
                        </td>
                    </tr>
                </tbody>
                <Dialog :style="{ width: '50rem' }" header="Finalizar visita" v-model:visible="mostrarFinalizar" @update:visible="mostrarFinalizar = false">                    
                    <div style="display: flex; flex-direction: column; align-items: center; ">
                        <h5>Trabajos realizados</h5>
                        <textarea v-model="trabajosRealizados" class="form-control" id="realizados" rows="4" style="width: 70%;"></textarea>
                        <h5 style="margin-top: 10px;">Trabajos pendientes</h5>
                        <textarea v-model="trabajosPendientes" class="form-control" id="pendientes" rows="4" style="width: 70%;"></textarea>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;margin-top: 20px" @click="finalizarvisita()">Finalizar</button>
                    </div>                    
                </Dialog>
            </table>
        </div>
    </div>
    <visita-editar 
        :idservicio="id"
        :idvisita="visitaaeditar.visita.idvisitador"
        :visita="visitaaeditar"
        :servicio="servicio"
    />
    <OverlayPanel ref="op" style="width: 600px;">
        <div style="display: grid; justify-content: space-around; grid-template-columns: repeat(3, 1fr); row-gap: 10px;">
            <div v-for="archivos in visitaamostrar.archivos" :key="archivos" style="justify-content: center;">
                <div style="display: flex; justify-content: center;" v-if="archivos.image==''">
                    <i class="fas fa-file fa-8x"></i>                    
                </div>
                <div style="display: flex; justify-content: center;" v-if="archivos.propiedad_cliente != 0">
                    <Image v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" + id' style="border: 5px solid goldenrod;" width="100" preview :alt="archivos.descripcion" />
                    <Image v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" + id' style="border: 5px solid goldenrod;" width="100" preview :alt="archivos.descripcion" />
                </div>
                <div style="display: flex; justify-content: center;" v-else>
                    <Image v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" + id' width="100" preview :alt="archivos.descripcion" />
                    <Image v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" + id' width="100" preview :alt="archivos.descripcion" />
                </div>
            </div>
        </div>
    </OverlayPanel>
    <OverlayPanel ref="opdos">
        <div class="row">
            <p v-html="infoamostrar"></p>
        </div>
    </OverlayPanel>
</template>

<script>
import visitaeditar from './visita_editar_general.vue';
import OverlayPanel from 'primevue/overlaypanel';
import { PwgsApi } from '../../../../../services/PwgsApi';
import Image from 'primevue/image';
import serviciodatosvisitasvisita from './visita_datos.vue';

export default {
    props: ['id', 'servicio', 'perjudicados', 'asegurado'],
    components: {
        'servicio-datos-visitas-visita': serviciodatosvisitasvisita,
        OverlayPanel, Image,
        'visita-editar': visitaeditar,
    },
    data() {
        return {
            trabajosRealizados:'',
            trabajosPendientes:'',
            visitaAMostrar:'',
            mostrarFinalizar: false,
            visitaaeditar:{
                "visita":{
                    "idvisitador":"1"
                }
            },
            urlglobal:'',
            mostrarBotones:[],
            ste:false,
            visitas: [],
            visitaamostrar: '',
            infoamostrar: '',
            visible: false,
            mostrarvisita:false,
            expandidaId: null // Para guardar el ID de la visita expandida
        };
    },
    methods: {
        mostrarFinalizarBtn(visita){
            this.visitaAMostrar = visita;
            this.trabajosRealizados = '';
            this.trabajosPendientes = '';
            this.mostrarFinalizar = true;
        },
        async comprobarSe(){
            const api = new PwgsApi();            
            const se = await api.get('/servicios/'+this.id+'/comunicaciones-ste');
            if(se.comunicaciones==1){
                this.ste = true;
            }
        },
        async finalizarvisita() {
            const visita = this.visitaAMostrar;
            const api = new PwgsApi();
            if(this.trabajosRealizados == ''){
                alert("Debe rellenar los trabajos realizados");
                return;
            }
            const body = {'trabajos_realizados_cita': this.trabajosRealizados, 'trabajos_pendientes_cita': this.trabajosPendientes};            
            try{
                const resp = await api.put('planning-pwgs/'+this.$props.id+'/finalizar-visita/'+visita.visita.idvisitador, body);
                console.log('finvisi', resp);
                this.mostrarFinalizar = false;
                this.obtenerVisitas(this.$props.id);
            }catch(e){
                alert(e);
                console.error('error',e);
            }
        },
        async smsoperario(visita) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-operario/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-operario/" + visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al operario correctamente', life: 2000 });

            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async smsasegurado(visita) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-asegurado/:id_visita
            const api = new PwgsApi();
            try {
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-asegurado/" + visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al asegurado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async steasegurado(visita) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ste-firma/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-ste-firma/" + visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async parteoperarioemail(visita) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ficha-operario/:id_visita
            const api = new PwgsApi();
            await api.put("planning-pwgs/" + this.$props.id + "/enviar-ficha-operario/" + visita.visita.idvisitador);
        },
        mostrarEditar(visita) {
            this.visitaaeditar = visita;
            this.visible = true;
        },
        toggleExpand(idvisitador) {
            this.expandidaId = this.expandidaId === idvisitador ? null : idvisitador;
            for(let btn of Object.keys(this.mostrarBotones)){
                if(idvisitador == btn){
                console.log;
                }else{
                    this.mostrarBotones[btn] = true;
                }
            }
            this.mostrarBotones[idvisitador] = !this.mostrarBotones[idvisitador];
        },
        handleRowClick(event, id) {
            if (!event.target.closest('.btn-group') && !event.target.closest('.dropdown-menu')) {
                this.toggleExpand(id);
            }
        },
        mostrarpresupuestos() {
            this.$parent.$parent.togglepresupuestos();
            if (this.$parent.$parent.presupuestos == false) { this.colorbotonpresup = ''; }
            if (this.$parent.$parent.presupuestos == true) { this.colorbotonpresup = 'green'; }
        },
        async obtenerVisitas(id, expandidaId = null) {
            // Obtiene visitas del servicio con el ID dado
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/'+id+'/visitas');
            this.visitas.sort((a, b) => parseInt(a.visita.fecha_entera_unix_visita) - parseInt(b.visita.fecha_entera_unix_visita));
            this.expandidaId = expandidaId; // Establece la visita a expandir si se proporciona
            for(let visi of this.visitas){
                this.mostrarBotones[visi.visita.idvisitador] = true;
            }
        },
        eliminarvisita(visita){
            const api = new PwgsApi();
            const res = api.delete('planning-pwgs/'+this.$props.id+'/eliminar-visita/'+visita.visita.idvisitador);
            console.log('eliminado',res);
            this.obtenerVisitas(this.$props.id);
        },
        mapf(visita){
            var mapfreUrl=[visita.fichas_operario.MAPFRE].slice(5);
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + mapfreUrl, "Mapfre", "width=800, height=600");
        },
        multi1(visita){
            var ms = visita.fichas_operario.MULTIASISTENCIA_WS[0]
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + ms, "Multiasistencia", "width=800, height=600");
        },
        multi2(visita){
            var ms = visita.fichas_operario.MULTIASISTENCIA_WS[1]
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + ms, "Multiasistencia", "width=800, height=600");
        },
        multi3(visita){
            var ms = visita.fichas_operario.MULTIASISTENCIA_WS[0]
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + ms, "Multiasistencia", "width=800, height=600");
        },
        toggle(event, visita) {
            this.cargarvisita(visita);
            this.$refs.op.toggle(event);
        },
        closeToggle() {
            this.$refs.op.hide();
        },
        cargarvisita(visita) {
            this.visitaamostrar = visita;
        },
        infoevento(event, texto) {
            this.infoamostrar = texto;
            this.$refs.opdos.toggle(event);
        },
        closeInfo() {
            this.$refs.opdos.hide();
        },
        async obtenerdocumentosvisitas() {
            const api = new PwgsApi();
            const aux = await api.get(`servicios/${this.$props.id}/datos-basicos`);
            this.documentosvisitas = this.$props.servicio.documentos.visitas;
            this.documentosreclamacion = aux.documentos.reclamacion;
        },
        formato(texto) {
            return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
        },
        abrirventana(visita) {
            var url = visita.fichas_operario.generico.slice(5);
            let urlglobal = localStorage.getItem('direccion');
            window.open("https://"+ urlglobal + url, "Parte genérico", "width=800, height=600");
        },
    },
    mounted(){
        this.comprobarSe();
        this.urlglobal = localStorage.getItem("direccion");
    },
    activated() {
        this.obtenerVisitas(this.$props.id);
        this.obtenerdocumentosvisitas();
    },
    watch: {
        servicio(){
            this.obtenerVisitas(this.$props.id);
        },
        id() {
            this.obtenerVisitas(this.$props.id);
            this.obtenerdocumentosvisitas();
        }
    }
};
</script>
